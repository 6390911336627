import React from 'react'

const BookingSidebarShimmer = () => {
  return (
    <div className={`pro-p-5`}>
        <div className={`pro-pb-4 pro-ps-6 pro-mb-5 shimmer`}></div>
        <div className={`pro-ps-4`}>
            <div className={`pro-d-flex pro-flex-column pro-items-start pro-mb-5 pro-pb-2`}>
                <div className='pro-d-inline-flex shimmer pro-mb-2'>
                    <div className={`pro-pb-4 pro-px-6 `}></div>
                    <div className={`pro-pb-4 pro-px-6 `}></div>
                </div>
                <div className={`pro-pb-2 pro-px-6 shimmer pro-mb-2`}></div>
                <div className={`pro-pb-2 pro-ps-6 pro-pe-5 shimmer`}></div>
            </div>
            <div className={`pro-d-flex pro-flex-column pro-items-start pro-mb-5 pro-pb-2`}>
                <div className='pro-d-inline-flex shimmer pro-mb-2'>
                    <div className={`pro-pb-4 pro-px-6 `}></div>
                    <div className={`pro-pb-4 pro-px-6 `}></div>
                </div>
                <div className={`pro-pb-2 pro-px-6 shimmer pro-mb-2`}></div>
                <div className={`pro-pb-2 pro-ps-6 pro-pe-5 shimmer`}></div>
            </div>
            <div className={`pro-d-flex pro-flex-column pro-items-start pro-mb-5 pro-pb-2`}>
                <div className='pro-d-inline-flex shimmer pro-mb-2'>
                    <div className={`pro-pb-4 pro-px-6 `}></div>
                    <div className={`pro-pb-4 pro-px-6 `}></div>
                </div>
                <div className={`pro-pb-2 pro-px-6 shimmer pro-mb-2`}></div>
                <div className={`pro-pb-2 pro-ps-6 pro-pe-5 shimmer`}></div>
            </div>
        </div>
    </div>
  )
}

export default BookingSidebarShimmer