import React from 'react'
import { Outlet } from 'react-router-dom'
import CommonLayout from '../../Pages/Layouts/CommonLayout'
const Dashboard = () => {
  return (
    <CommonLayout dashboard isGrey>
      <Outlet/>
    </CommonLayout>
  )
}

export default Dashboard
