import { useGetDashboardDataQuery } from "../../../Store/Queries/Dashboard";
import { updateConfig } from "../../../Store/Slices/Dashboard/dashboardSlice";
import { subDays, format } from "date-fns";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Chart, registerables } from "chart.js";
import { useState } from "react";
Chart.register(...registerables);

const useDashboardHome = () => {
  const dispatch = useDispatch();
  const dashboardState = useSelector((state) => state.dashboard);
  const globalState = useSelector((state) => state.global);
  const [initialDate] = useState({
    startDate: subDays(new Date(), 30),
    endDate: subDays(new Date(), 0),
    key: "selection",
  });
  const {
    data: dashboardList = {},
    isFetching,
  } = useGetDashboardDataQuery({
    start:
      dashboardState.start === ""
        ? format(initialDate?.startDate, "dd-MM-yyyy")
        : dashboardState.start,
    end:
      dashboardState.end === ""
        ? format(initialDate?.endDate, "dd-MM-yyyy")
        : dashboardState.end,
  });
  let cardDetails = {};
  let userStatusLabel=[]
  let userStatusValue=[]
  let totalUsers=""
  let userBackgroundColor=[]
  
  let serviceStatusLabel=[]
  let serviceStatusValue=[]
  let totalStatusService=""
  let serviceBackgroundColor=[]

  if (dashboardList?.data?.user_count) {
    cardDetails = Object.keys(dashboardList.data.user_count)
      .filter((objKey) => objKey !== "user_status")
      .reduce((newObj, key) => {
        newObj[key] = dashboardList.data.user_count[key];
        return newObj;
      }, {});
      userStatusLabel=dashboardList?.data?.user_count?.user_status?.labels
      userStatusValue=dashboardList?.data?.user_count?.user_status?.datasets
      totalUsers=dashboardList?.data?.user_count?.user_status?.total_users
      userBackgroundColor=dashboardList?.data?.user_count?.user_status?.backgroundColor
  }
  if (dashboardList?.data?.service_request) {
      serviceStatusLabel=dashboardList?.data?.service_request?.labels
      serviceStatusValue=dashboardList?.data?.service_request?.datasets
      totalStatusService=dashboardList?.data?.service_request?.total_count
      serviceBackgroundColor=dashboardList?.data?.service_request?.backgroundColor
  }
  let data= dashboardList?.data?.service_category?dashboardList?.data?.service_category:[]
  const dashboardItems = {
    total_users: {
      label: "total_users",
      title: "Total Users",
      icon: "group",
    },
    provider_count: {
      label: "provider_count",
      title: "Providers",
      icon: "heart_check",
    },
    seeker_count: {
      label: "seeker_count",
      title: "Seekers",
      icon: "how_to_reg",
    },
  };
let isLightMode = globalState.currentTheme === "light" || globalState.currentTheme === null;

  const statusGraph = {
    plugins: {
      legend: {
        display: false,  // Hide the legend
      },
      afterDraw: () => {},
    },
    scales: {
      y: {
        display: false,
      },
      x: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    width: 300,
    height: 300,
    cutout: "65%",
    aspectRatio: 1,
  };
  const doughnutData = {
    labels: userStatusLabel,
    datasets: [
      {
        data: userStatusValue,
        backgroundColor: userBackgroundColor,
      },
    ],
  };
  const doughnutTextCenter = {
    id: "doughnutTextCenter",
    afterDatasetsDraw(chart, args, plugins) {
      const { ctx, chartArea: { width, height } } = chart;
      const centerX = width / 2;
      const centerY = height / 2;
      const value = totalUsers;
      const text = "Users";
  
      ctx.save();
      ctx.fillStyle = isLightMode ? "black" : "white";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
  
      // Draw the text above the value
      ctx.font = `300 20px sans-serif`; // Light boldness for text
      ctx.fillText(text, centerX, centerY - 20);
  
      // Draw the value below the text
      ctx.font = `bold 35px sans-serif`; // Heavier boldness for value
      ctx.fillText(value ? `${value}` : `0`, centerX, centerY + 20);
  
      ctx.restore();
    },
  };
  const doughnutServiceData = {
    labels: serviceStatusLabel,
    datasets: [
      {
        data: serviceStatusValue,
        backgroundColor: serviceBackgroundColor,
      },
    ],
  };
  
  const doughnutTextCenterService = {
    id: "doughnutTextCenterService",
    afterDatasetsDraw(chart, args, plugins) {
      const { ctx, chartArea: { width, height } } = chart;
      const centerX = width / 2;
      const centerY = height / 2;
      const value = totalStatusService;
      const text = "Service Status";
  
      ctx.save();
      ctx.fillStyle = isLightMode ? "black" : "white";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
  
      ctx.font = `300 20px sans-serif`; 
      ctx.fillText(text, centerX, centerY - 20);
  
      ctx.font = `bold 35px sans-serif`; 
      ctx.fillText(value ? `${value}` : `0`, centerX, centerY + 20);
  
      ctx.restore();
    },
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
      tooltip: {
        enabled: false, // Hide tooltips
      },
      title: {
        display: false, // Hide title
      },
    },
    scales: {
      x: {
        display: false, // Hide x-axis
        grid: {
          display: false, // Hide x-axis grid lines
        },
        // Adjust bar thickness
        stacked: true, // Ensure bars stack on top of each other
        barThickness: 5, // Set the bar thickness
        minBarLength: 55, // Minimum length of bars to avoid disappearing
      },
      y: {
        display: false, // Hide y-axis
        grid: {
          display: false, // Hide y-axis grid lines
        },
        ticks: {
          display: false, // Hide y-axis ticks
        },
      },
    },
  };
  const handleDateChange = (date) => {
    dispatch(
      updateConfig((state) => {
        state.start = format(new Date(date.startDate), "dd-MM-yyyy");
        state.end = format(new Date(date.endDate), "dd-MM-yyyy");
      })
    );
  };
  return {
    dashboardItems,
    isFetching,
    dashboardList,
    cardDetails,
    initialDate,
    handleDateChange,
    statusGraph,
    doughnutData,
    doughnutTextCenter,
    totalUsers,
    data,options,
    doughnutTextCenterService,doughnutServiceData,totalStatusService
  };
};

export default useDashboardHome;
