import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../../Store/Slices/Users/providersSlice";
import { OtpVerification, Otpsend } from "../../api";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import countryData from "../../../../../utils/countryCode";

const useBasicdetails = ({ formik, status, setStatus }) => {
  const dispatch = useDispatch();
  const { isEdit, imagePreview } = useSelector((state) => state.providers);
  const { basicData } = useSelector((state) => state.global);
  const [otpnumber, setOtpnumber] = useState();
  const [submitname, setSubmitname] = useState("Send OTP");
  const [otpemail, setOtpemail] = useState();
  const [submitemail, setSubmitemail] = useState("Send OTP");
  const [otpnumbershown, setOtpnumbershown] = useState(false);
  const [otpemailshown, setOtpemailshown] = useState(false);
  const handleStatusChange = () => {
    setStatus(!status);
  };
  useEffect(() => {
    if (isEdit) {
      setSubmitemail("Edit");
      setSubmitname("Edit");
      setOtpnumbershown(false);
      setOtpemailshown(false);
    }
  }, [isEdit]);
  const numberChange = () => {
    setSubmitname("Send OTP");
    setOtpnumber();
    dispatch(
      updateConfig((state) => {
        state.phoneverified = false;
      })
    );
  };
  const otpreq = () => {
    let data = {
      country_code: formik?.values?.country_code?.value,
      phone_number: formik?.values?.phone,
      // "email":"pereus21@gmail.com"
    };
    Otpsend(data).then((response) => {
      if (response?.data?.success === true) {
        setSubmitname("Edit");
        setOtpnumbershown(true);
        toast.success(response?.data?.message);
      } else {
        setOtpnumbershown(false);
        toast.error(response?.message?.phone_number);
        setSubmitname("Send OTP");
      }
    });
  };
  const handleotpsend = () => {
    const phonePattern = /^[^\s].*$/;
    const isPhoneValid = phonePattern.test(formik?.values?.phone);
    if (
      isPhoneValid &&
      formik?.values?.phone?.length >= 7 &&
      formik?.values?.phone?.length <= 14
    ) {
      if (submitname === "Edit") {
        numberChange();
        setOtpnumbershown(false);
      } else {
        otpreq();
      }
    } else {
      numberChange();
      setOtpnumbershown(false);
    }
  };
  const otpverify = () => {
    if (!formik?.errors?.phone) {
      let data = {
        country_code: formik?.values?.country_code?.value,
        phone_number: formik?.values?.phone,
        otp: Number(otpnumber),
      };
      OtpVerification(data).then((response) => {
        if (response?.data?.success === true) {
          dispatch(
            updateConfig((state) => {
              state.phoneverified = true;
            })
          );
          setSubmitname("Verified");
          setOtpnumbershown(false);
          toast.success(response?.message);
        } else {
          dispatch(
            updateConfig((state) => {
              state.phoneverified = false;
            })
          );
          setSubmitname("Edit");
          setOtpnumbershown(true);
          toast.error(response?.message);
        }
      });
    }
  };

  const emailChange = () => {
    setSubmitemail("Send OTP");
    setOtpemail();
    dispatch(
      updateConfig((state) => {
        state.emailverified = false;
      })
    );
  };
  const otpreqemail = () => {
    let data = {
      email: formik?.values?.email,
    };
    Otpsend(data).then((response) => {
      if (response?.data?.success === true) {
        setSubmitemail("Edit");
        setOtpemailshown(true);
        toast.success(response?.data?.message);
      } else {
        setSubmitemail("Send OTP");
        setOtpemailshown(false);
        toast.error(response?.message?.email);
      }
    });
  };
  const otpsendemail = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isEmailValid = emailPattern.test(formik?.values?.email);
    if (isEmailValid) {
      if (submitemail === "Edit") {
        emailChange();
        setOtpemailshown(false);
      } else {
        otpreqemail();
      }
    } else {
      emailChange();
      setOtpemailshown(false);
    }
  };
  const otpverifyemail = () => {
    if (!formik?.errors?.email) {
      let data = {
        email: formik?.values?.email,
        otp: Number(otpemail),
      };
      OtpVerification(data).then((response) => {
        if (response?.status === 200) {
          dispatch(
            updateConfig((state) => {
              state.emailverified = true;
            })
          );
          setOtpemailshown(false);
          setSubmitemail("Verified");
          toast.success(response?.data?.message);
        } else {
          dispatch(
            updateConfig((state) => {
              state.emailverified = false;
            })
          );
          setSubmitemail("Edit");
          setOtpemailshown(true);
          toast.error(response?.message);
        }
      });
    }
  };
  const handleNext = () => {
    dispatch(updateConfig((state) => (state.formActiveTab = "Language")));
  };
  const handleProfileChange = (event) => {
    const selectedImage = event?.target?.files[0];
    if (selectedImage) {
      formik.setFieldValue("profile_image", selectedImage);
      const file = selectedImage;
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(
          updateConfig((state) => {
            state.imagePreview = reader.result;
          })
        );
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };
  const handleProfileRemove = () => {
    dispatch(
      updateConfig((state) => {
        state.imagePreview = "";
      })
    );
    formik.setFieldValue("profile_image", "");
    formik?.setFieldError("profile_image", "Upload image");
    formik?.setFieldTouched("profile_image", true);
  };
  const handlePhoneButton = () => {
    if (submitname === "Edit" || submitname === "Send OTP") {
      handleotpsend();
    } else {
      return;
    }
  };
  const handleEmailButton = () => {
    if (submitemail==="Edit"||submitemail==="Send OTP") {
        otpsendemail();
      } else {
        return ;
      }
  };
  return {
    isEdit,
    status,
    handleStatusChange,
    basicData,
    countryCodeOptions: countryData,
    handleotpsend,
    otpverify,
    setOtpnumber,
    otpnumber,
    otpemail,
    numberChange,
    submitname,
    otpreq,
    emailChange,
    otpreqemail,
    otpsendemail,
    otpverifyemail,
    submitemail,
    setOtpemail,
    handleNext,
    handleProfileChange,
    handleProfileRemove,
    imagePreview,
    otpnumbershown,
    otpemailshown,
    handlePhoneButton,
    handleEmailButton
  };
};

export default useBasicdetails;
