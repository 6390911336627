import React, { useState } from 'react';
import Style from './ColorPricker.module.scss';

const ColorPicker = ({
  id,
  name,
  value = '',
  onChange = () => {},
  isError = false,
  errorMessage,
  onBlur,
  ...props
}) => {
  const [colorValue, setColorValue] = useState(null);

  const handleColorChange = (newColor) => {
    setColorValue(newColor);
    onChange(newColor);
  };

  return (
    <div className={`${Style.color_picker} pro-mb-3  pro-w-100 ${isError ? Style.error : ''}`}>
      <label htmlFor={id}></label>
      {!colorValue && (
        <span className={`material-symbols-outlined ${Style.icon}`}>colorize</span>
      )}
      <input
        id={id}
        name={name}
        type="color"
        value={colorValue || value}
        onChange={(e) => handleColorChange(e.target.value)}
        className={`pro-input lg pro-p-0 pro-m-0 ${Style.color_picked}`}
        style={{ borderColor: colorValue || value }}
        onBlur={onBlur}
        {...props}
      />
      <input
        type="text"
        value={colorValue || value}
        readOnly
        className={`pro-input lg pro-m-0 ${Style.color_val}`}
        placeholder='Select Color'
      />
      {isError && errorMessage && (
        <span className={`error-custom ${Style.error_msg}`}>{errorMessage}</span>
      )}
    </div>
  );
};

export default ColorPicker;