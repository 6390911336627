import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    start:"",
    end:"",
};

const DashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = DashboardSlice.actions;
export default DashboardSlice.reducer;
