import React from "react";
import { Link, Outlet } from "react-router-dom";
import ProtectRoute from "../../../../utils/components/ProtectRoute/ProtectRoute";
import {
  Header,
  IconText,
  ProfileSideBar,
} from "@wac-ui-dashboard/wac_component_library";
import useCommonLayout from "../../../../Pages/Layouts/CommonLayout/useCommonLayout";
import Assets from "../../../../Assets";
import HeaderActions from "../../../../Pages/Layouts/CommonLayout/HeaderActions";
import Style from "../../../../Pages/Layouts/CommonLayout/commonLayout.module.scss";
import bookingStyle from "./bookingDetailsLayout.module.scss";
import useBookingDetailsLayout from "./useBookingDetailsLayout";
import EmptyData from "../../../Global/EmptyData";
import BookingSidebarShimmer from "../../../Global/Shimmers/BookingSidebarShimmer";

const BookingDetailsLayout = () => {
  const { navigations, globalState } = useCommonLayout();
  const { isFetching, bookingdetailData, handleBackClick } =
    useBookingDetailsLayout();
  return (
    <ProtectRoute>
      <Header
        navigations={navigations}
        Link={Link}
        logo={
          globalState.currentTheme === "light" ||
          globalState.currentTheme === null
            ? Assets.HEADER
            : Assets.LOGO_INVERT
        }
        children={<HeaderActions />}
        propStyle={{
          header: Style.header,
          headerInner: Style.header_inner,
        }}
      />

      <div
        className={`pro-w-100 container-fluid pt-custom ${bookingStyle.main_wrapper}`}
      >
        <div className={`row gx-0`}>
          {/* side bar left */}
          <div className={`${bookingStyle.left_sidebar}`}>
            {isFetching &&  Object.keys(bookingdetailData?.data ?? {})?.length === 0 ? (
              <>
                <BookingSidebarShimmer />
              </>
            ) : (
              <>
                <ProfileSideBar
                  extraClassName={bookingStyle.left_sidebar_sticky}
                >
                  <div className={` pro-ps-2 pro-pe-5 pro-pt-4`}>
                    <div className={`pro-pb-3  pro-mb-4`}>
                      <button
                        className="pro-back-btn pro-items-center pro-d-flex"
                        onClick={handleBackClick}
                      >
                        <span className="icon">
                          <span className="material-symbols-outlined x4">
                            keyboard_arrow_left
                          </span>
                        </span>
                        <span className="title">
                          <b>Back </b>
                        </span>
                      </button>
                    </div>

                    <div
                      className={`pro-rtl ${bookingStyle.booking_track_wrap}`}
                    >
                      {bookingdetailData?.data?.history.length > 0 ? (
                        bookingdetailData?.data?.history?.map(
                          (value, index) => {
                            return (
                              <div
                                key={index}
                                className={`pro-d-flex ${bookingStyle.tracking_level}`}
                              >
                                <div
                                  className={`${bookingStyle.tracking_status}`}
                                >
                                  <span
                                    className={`${bookingStyle.status}  ${
                                      value.status === 0
                                        ? bookingStyle.pending
                                        : value.status === 1
                                        ? bookingStyle.accepted
                                        : value.status === 2
                                        ? bookingStyle.rejected
                                        : value.status === 3
                                        ? bookingStyle.complete
                                        : value.status === 4
                                        ? bookingStyle.cancelled
                                        : bookingStyle.incomplete
                                    } `}
                                  ></span>
                                </div>
                                <div
                                  className={`${bookingStyle.tracking_status_details}`}
                                >
                                  <h6 className={`${bookingStyle.title}`}>
                                    {value?.message}
                                  </h6>
                                  <div className="icontxt_no_hover">
                                    <IconText
                                      icon={
                                        <img
                                          src={value?.profile_image}
                                          alt={"profile-pic"}
                                          width={20}
                                          height={20}
                                          className="object-fit-cover"
                                        />
                                      }
                                      title={value?.user_name}
                                      propStyle={{
                                        rootIcon:
                                          "pro-d-flex pro-items-center pro-rounded-circle pro-overflow-hidden",
                                      }}
                                    />
                                  </div>
                                  <p
                                    className={`pro-mt-1 ${bookingStyle.date}`}
                                  >
                                    {value?.created_at}
                                  </p>
                                </div>
                              </div>
                            );
                          }
                        )
                      ) : (
                        <EmptyData/>
                      )}
                    </div>
                  </div>
                </ProfileSideBar>
              </>
            )}
          </div>

          {/* main container */}
          <div className={`${bookingStyle.main_container}`}>
            <Outlet context={{ bookingdetailData }} />
          </div>
        </div>
      </div>
    </ProtectRoute>
  );
};

export default BookingDetailsLayout;
