import {
  HeadingGroup,
  Pagination,
  SearchFilters,
  Table
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import EmptyData from "../../../../Global/EmptyData";
import useProviderBooking from "./useProviderBooking";

const ProviderBooking = ({ activeProvider }) => {
  const {
    isLoading,
    isFetching,
    tableFields,
    providerBookingList,
    providersState,
    paginationOptions,
    getRow,
    handleSearch,
    handlePageSize,
    handlePagination,
    handleClearClick
  } = useProviderBooking({ activeProvider });
  return (
    <div>

     
      <div className="col-auto pro-pt-3 pro-pb-6">
        <div className="pro-d-flex pro-justify-start">
          <HeadingGroup
            title={"Bookings"}
            extraClassName={`pro-mb-1 pro-me-3 heading-wrap pro-w-auto`}
          />  
          <SearchFilters
            onSearchInput={handleSearch}
            showActions={false}
            loading={isLoading}
            SearchIcon={
              <span className="material-symbols-outlined"> search </span>
            }
            searchInputProps={{ value: providersState?.search }}
            extraFilters={
              <>
              <div className="col-auto pro-d-flex">    
                <button
                  className={" pro-btn-link lg"}
                  onClick={handleClearClick}
                >        
                  Clear
                </button>
              </div>
              </>
            }
          />

        </div>
        {providerBookingList?.data?.data?.length !== 0 ? (
          <>
            <Table
              multiSelect={false}
              data={providerBookingList?.data?.data || []}
              uniqueID={"_id"}
              fields={tableFields}
              getRow={getRow}
              loading={isFetching}
              perpage={providersState?.currentPageSize}
              assignable={false}
            />
            {providerBookingList?.data?.data?.length > 0 && (

              <Pagination
                currentPage={providersState?.currentPage}
                totalPageCount={Math.ceil(
                  providerBookingList?.data?.total /
                  providersState?.details_bookingPage?.currentPageSize,
                )}
                options={paginationOptions}
                onPageChange={handlePagination}
                onActionChange={handlePageSize}
                center
              />
            )}
          </>
        ) : (
          <EmptyData />
        )}
      </div>
    </div>
  );
};

export default ProviderBooking;
