import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import EmptyData from "../../Global/EmptyData";
import OrderColumn from "../../Global/OrderColumn";
import useVerification from "./useVerification";
import { DocumentCard } from "./DocumentCard";

const Verification = () => {
  const {
    isLoading,
    isFetching,
    tableFields,
    verificationList,
    verificationState,
    paginationOptions,
    getRow,
    refetch,
    handleSort,
    handleSearch,
    handlePageSize,
    handlePagination,
    handleCloseClick,
    handleCreateClick,
    actionOptions,
    handleActionChange,
    updateStatusFields,
    showEditModal,
    handleDateChange,
    initialDate,
    showDocumentVerificationModal,
    closeDocumentModal,
    activeProfileID,
    setShowDocumentVerificationModal,
    handleClearClick
  } = useVerification();
  return (
    <div>
      <HeadingGroup
        title={"Verifications"}
        className={`pro-mb-4`}
        buttonTitle={verificationList?.data?.permission?.can_create && "Create"}
        handleClick={handleCreateClick}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <div className="pro-d-flex search-filter-datepicker-wrap search_filter_shimmer">
          <SearchFilters
            onSearchInput={handleSearch}
            showActions={false}
            loading={isLoading}
            SearchIcon={
              <span className="material-symbols-outlined"> search </span>
            }
            searchInputProps={{ value: verificationState?.search }}
            actionOptions={actionOptions?.filter((item) => item.value === 0)}
            onActionOptionChange={handleActionChange}
            isDateRange
            showDateRange={true}
            initialDateRange={initialDate}
            onDateChange={handleDateChange}
            extraFilters={
              <>
              <div className="col-auto pro-d-flex">    
                <button
                  className={" pro-btn-link lg"}
                  onClick={handleClearClick}
                >        
                  Clear
                </button>
              </div>
              </>
            }
          />
        </div>
        {verificationList?.data?.data?.length !== 0 ? (
          <>
            <Table
              multiSelect={false}
              data={verificationList?.data?.data || []}
              uniqueID={"_id"}
              fields={tableFields}
              SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              handleSort={handleSort}
              clear={verificationState.clearSelection}
              getRow={getRow}
              loading={isFetching}
              perpage={verificationState?.currentPageSize}
              editable={verificationList?.data?.permission?.can_update}
              deletable={verificationList?.data?.permission?.can_delete}
              assignable={false}
              // showCheckBox={verificationList?.data?.permission?.can_update}
            />
            {verificationList?.data?.data?.length > 0 && (
              <Pagination
                currentPage={verificationState?.currentPage}
                totalPageCount={Math.ceil(
                  verificationList?.data?.total /
                    verificationState.currentPageSize
                )}
                options={paginationOptions}
                onPageChange={handlePagination}
                onActionChange={handlePageSize}
                center
              />
            )}
          </>
        ) : (
          <EmptyData />
        )}
        <ModalLayout show={showEditModal} handleClose={handleCloseClick}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              tableFields={verificationList?.data?.fields}
              moduleId={verificationList?.data?.module_id}
              updateData={updateStatusFields}
              refetch={refetch}
            />
          </div>
        </ModalLayout>
        <ModalLayout
          show={showDocumentVerificationModal}
          handleClose={closeDocumentModal}
        >
          <DocumentCard
            data={verificationState}
            activeID={activeProfileID}
            refetch={refetch}
            setShowDocumentVerificationModal={setShowDocumentVerificationModal}
          />
        </ModalLayout>
      </div>
    </div>
  );
};

export default Verification;
