import { getAxiosInstance } from "../../../API";

export const createSeeker= async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/service-users/create`, body);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};

export const updateSeeker = async (body) => {
  const api = await getAxiosInstance();;
  try {
    const response = await api.post(`/admin/service-users/update`, body, {
      headers: {
        'X-HTTP-Method-Override': 'PUT'
      }
    });
    return response;
  } catch (error) {
    return error?.response?.data
  }
};

export const viewSeeker = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/admin/service-users/edit?service_user_id=${id}`);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};

export const updateStatus = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/service-users/status/change`, body);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};
export const deleteSeeker = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.delete(`/admin/service-users/delete?service_user_id=${id}`);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};
export const Otpsend = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/otp/send`, body);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};
export const OtpVerification = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/otp/verify`, body);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};
export const exportOrderReport = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/service-users/export`, body,{
      responseType: 'blob' 
    });
    return response;
  } catch (error) {
    return error?.response?.data
  }
};