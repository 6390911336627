import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../Store/Slices/Users/seekerdetailSlice";
import SeekerBooking from "./SeekerBooking";
import SeekerPosts from "./SeekerPosts";
import SeekerPayments from "./SeekerPayments";

const useSeekersDetails = () => {
    const { seekerID } = useParams();
    let activeTab = "";
    const dispatch = useDispatch();
    const activeSeeker = seekerID.replace(/activeTab=[^&]+/, "");
    const seekerdatastate = useSelector((state) => state.seekerdetails);

    const url = window.location.href;
    const activeTabParam = "activeTab=";
    const activeTabIndex = url.indexOf(activeTabParam);
    if (activeTabIndex !== -1) {
        const startIndex = activeTabIndex + activeTabParam.length;
        const endIndex =
            url.indexOf("&", startIndex) !== -1
                ? url.indexOf("&", startIndex)
                : url.length;
        activeTab = url.substring(startIndex, endIndex);
        activeTab = decodeURIComponent(activeTab);
    }

    useEffect(() => {
        dispatch(
            updateConfig((state) => (state.details_bookingPage.activeTab = activeTab))
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab]);
    const navigation = [
        {
            label: "Bookings",
            title: "Bookings",
            link: `/users/seeker-details/${activeSeeker}activeTab=bookings`,
            active: activeTab.includes("bookings"),
        },
        {
            label: "Posts",
            title: "Posts",
            link: `/users/seeker-details/${activeSeeker}activeTab=posts`,
            active: activeTab.includes("posts"),
        },
        {
            label: "Payments",
            title: "Payments",
            link: `/users/seeker-details/${activeSeeker}activeTab=payments`,
            active: activeTab.includes("payments"),
        },
    ];
    const renderTabContent = () => {
        switch (seekerdatastate?.details_bookingPage.activeTab) {
            case "bookings":
                return (
                    <SeekerBooking
                        activeSeeker={activeSeeker} />
                );
            case "posts":
                return (
                    <SeekerPosts
                    activeSeeker={activeSeeker}
                    />
                )
            case "payments":
                return (
                    <SeekerPayments activeSeeker={activeSeeker} />
                );
            default:
                return null;
        }
    };
    return {
        tabNavs: navigation,
        renderTabContent,
    };
};

export default useSeekersDetails;
