import { useSelector } from "react-redux";
import {
  payments,
  useGetPaymentsDataQuery,
  usePaymentsTableHeadDataMutation,
} from "../../../Store/Queries/Payments";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../Store/Slices/Global";
import { updateConfig } from "../../../Store/Slices/Payments/paymentsSlice";
import { updateStatus, deletePayments } from "../api";
import { toast } from "react-toastify";
import { current } from "@reduxjs/toolkit";
import { subDays, format } from "date-fns";

const usePaymentsHome = () => {
  const dispatch = useDispatch();
  const paymentsState = useSelector((state) => state.payments);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  let actionOptions = [{ label: "Edit Columns", value: 0 }];
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const [initialDate, setInitialDate] = useState({
    startDate: subDays(new Date(), 30),
    endDate: subDays(new Date(), 0),
    key: "selection",
  });
  const {
    data: paymentsList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetPaymentsDataQuery({
    page_size: paymentsState.currentPageSize,
    currentPage: paymentsState.currentPage,
    sort_by: paymentsState.sortBy,
    sort_order: paymentsState.sortOrder,
    start:
      paymentsState.start === ""
        ? format(initialDate?.startDate, "dd-MM-yyyy")
        : paymentsState.start,
    end:
      paymentsState.end === ""
        ? format(initialDate?.endDate, "dd-MM-yyyy")
        : paymentsState.end,
    search: paymentsState.search,
    status: paymentsState?.status,
    service_type_id: paymentsState?.service_type_id,
    schedule: paymentsState?.schedule,
  });
  const [updateStatusFields] = usePaymentsTableHeadDataMutation();
  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = paymentsList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [paymentsList]);

  const handleChange = (data) => {
    let params = {
      post_id: data?._id,
      status: data.status === 1 ? 0 : 1,
    };
    updateStatus(params);
    dispatch(
      payments.util.updateQueryData(
        "getPostsData",
        {
          page_size: paymentsState.currentPageSize,
          currentPage: paymentsState.currentPage,
          sort_by: paymentsState.sortBy,
          sort_order: paymentsState.sortOrder,
          start:
            paymentsState.start === ""
              ? format(initialDate?.startDate, "dd-MM-yyyy")
              : paymentsState.start,
          end:
            paymentsState.end === ""
              ? format(initialDate?.endDate, "dd-MM-yyyy")
              : paymentsState.end,
          search: paymentsState.search,
          status: paymentsState?.status,
          service_type_id: paymentsState?.service_type_id,
          schedule: paymentsState?.schedule,
        },
        (cachedData) => {
          let updatedCachedData = { ...current(cachedData) };
          let newResult = updatedCachedData?.data?.data?.map((res) =>
            res?._id === data?._id
              ? {
                  ...res,
                  status: data?.status === 1 ? 0 : 1,
                }
              : res
          );
          updatedCachedData = {
            ...updatedCachedData,
            data: {
              ...updatedCachedData.data,
              data: newResult,
            },
          };
          return updatedCachedData;
        }
      )
    );
  };
  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      status: (feild, data) => {
        const status = data[feild] === 1;
        return (
          <>
            <div className="pro-toggle">
              <div className="pro-toggle-box">
                <p
                  className={`pro-badge pro-mb-0 ${
                    data[feild] === "Completed"
                      ? "badge-success-outline"
                      : data[feild] === "Accepted"
                      ? " badge-success-outline"
                      : data[feild] === "Pending"
                      ? " badge-warning-outline"
                      : data[feild] === "Rejected"
                      ? " badge-danger-outline "
                      : "badge-outline"
                  }`}
                >
                  {data[feild]}
                </p>
                <span></span>
              </div>
            </div>
          </>
        );
      },
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = 10;
        state.currentPage = 1;
        state.sortBy = "";
        state.sortOrder = "";
        state.start = format(subDays(new Date(), 30), "dd-MM-yyyy");
        state.end = format(new Date(), "dd-MM-yyyy");
        state.search = "";
      })
    );
    setInitialDate({
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: "selection",
    });
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (paymentsState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = paymentsState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.isEdit = false;
        state.createModal = true;
      })
    );
  };

  const handleCloseClick = () => {
    dispatch(updateConfig((state) => (state.createModal = false)));
  };
  const handleDeleteAction = () => {
    let id = paymentsState?.itemId;
    deletePayments(id).then((response) => {
      if (response?.status === 200) {
        toast.success("Deleted");
        refetch();
        handleDeleteCloseModal();
        dispatch(updateConfig((state) => (state.clearSelection = true)));
        const clearSelection = setTimeout(() => {
          dispatch(updateConfig((state) => (state.clearSelection = false)));
          clearTimeout(clearSelection);
        }, 500);
      } else {
        toast.error("Something went wrong");
        handleDeleteCloseModal();
      }
    });
  };
  const handleDateChange = (date) => {
    dispatch(
      updateConfig((state) => {
        state.start = format(new Date(date.startDate), "dd-MM-yyyy");
        state.end = format(new Date(date.endDate), "dd-MM-yyyy");
      })
    );
  };
  const handleDeleteCloseModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.deleteModal = !state.deleteModal;
        state.itemId = "";
        state.itemData = "";
      })
    );
  };
  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.deleteModal = !state.deleteModal;
        state.itemId = e?.[0];
      })
    );
  };
  // const handleFilter = () => {
  //   dispatch(
  //     updateConfig((state) => {
  //       state.isFilter = false;
  //       state.createFilterModal = true;
  //     })
  //   );
  // };
  const handleCloseFilter = () => {
    dispatch(updateConfig((state) => (state.createFilterModal = false)));
  };
  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };
  return {
    isLoading,
    isFetching,
    tableFields,
    paymentsList,
    paymentsState,
    paginationOptions,
    getRow,
    refetch,
    handleSort,
    handleSearch,
    handlePageSize,
    handlePagination,
    handleCloseClick,
    handleClearClick,
    handleCreateClick,
    handleDeleteModal,
    handleDeleteCloseModal,
    handleDeleteAction,
    // handleFilter,
    handleCloseFilter,
    actionOptions,
    handleActionChange,
    updateStatusFields,
    showEditModal,
    handleDateChange,
    initialDate,
  };
};

export default usePaymentsHome;
