import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../../Store/Slices/Users/providersSlice";
import { useSelector } from "react-redux";
import { useState } from "react";

const useServices = ({ formik, basicData }) => {
  const { isEdit } = useSelector((state) => state.providers);
  const dispatch = useDispatch();
  const [activeKey, setActiveKey] = useState(null);

  const handleAddService = () => {
    let { service_type } = formik?.values;
    service_type = [
      ...service_type,
      {
        _id: "",
      },
    ];
    formik.setFieldValue("service_type", service_type);

    const newIndex = formik?.values?.service_type?.length; 
    setActiveKey(newIndex?.toString());

  };

  const handleAccordionToggle = (key) => {
    setActiveKey((prevKey) => (prevKey === key ? null : key));
  };

  const handleCloseModal = () => {
    dispatch(updateConfig((state) => {
      state.createModal = false;
      state.itemData = "";
      state.imagePreview = "";
      state.documents=[];
      state.phoneverified = false;
      state.emailverified = false;
      state.formActiveTab= "BasicDetails";
  }));
  };

  const handleNext = () => {
    dispatch(updateConfig((state) => (state.formActiveTab = "Documents")));
  };
  return {
    isEdit,
    handleCloseModal,
    handleAddService,
    handleNext,
    handleAccordionToggle,
    activeKey,
  };
};

export default useServices;
