import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../Store/Slices/Users/providerdetailSlice";
import { useGetProviderdetailQuery } from "../../../../Store/Queries/Users/Providers";
import ProviderDocuments from "./ProviderDocuments";
import ProviderBooking from "./ProviderBooking";
import ProviderPayments from "./ProviderPayments";

const useProvidersDetails = () => {
  const { providerID } = useParams();
  let activeTab = "";
  const dispatch = useDispatch();
  const activeProvider =providerID.replace(/activeTab=[^&]+/, "") ??sessionStorage.getItem("active-campaign");
  const providerdatastate = useSelector((state) => state.providerdata);

  const { data: providerData, isFetching } = useGetProviderdetailQuery({
    service_provider_id: activeProvider,
  });

  const url = window.location.href;
  const activeTabParam = "activeTab=";
  const activeTabIndex = url.indexOf(activeTabParam);
  if (activeTabIndex !== -1) {
    const startIndex = activeTabIndex + activeTabParam.length;
    const endIndex =
      url.indexOf("&", startIndex) !== -1
        ? url.indexOf("&", startIndex)
        : url.length;
    activeTab = url.substring(startIndex, endIndex);
    activeTab = decodeURIComponent(activeTab); 
  }

  useEffect(() => {
    dispatch(
      updateConfig((state) => (state.details_bookingPage.activeTab = activeTab))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);
  const navigation = [
    {
      label: "Documents",
      title: "Documents",
      link: `/users/providers-details/${activeProvider}activeTab=documents`,
      active: activeTab.includes("documents"),
    },
    {
      label: "Bookings",
      title: "Bookings",
      link: `/users/providers-details/${activeProvider}activeTab=booking`,
      active: activeTab.includes("booking"),
    },
    {
      label: "Payments",
      title: "Payments",
      link: `/users/providers-details/${activeProvider}activeTab=payments`,
      active: activeTab.includes("payments"),
    },
  ];

  const renderTabContent = () => {
    switch (providerdatastate?.details_bookingPage.activeTab) {
      case "documents":
        return (
          <ProviderDocuments
          providerData={providerData}
          isFetching={isFetching}
          />
        );
      case "booking":
        return (
          <ProviderBooking
          activeProvider={activeProvider} />
        );
      case "payments":
        return (
          <ProviderPayments activeProvider={activeProvider} />
        );
      default:
        return null;
    }
  };
  return {
    tabNavs: navigation,
    renderTabContent,
  };
};

export default useProvidersDetails;
