import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import Style from "./DocumentCard.module.scss";
import useDocumentCard from "./useDocumentCard";
import { DataContainer, Image } from "@wac-ui-dashboard/wac_component_library";
import Assets from "../../../../Assets";
// import { useState } from "react";

export function DocumentCard({
  data,
  activeID,
  refetch,
  setShowDocumentVerificationModal,
}) {
  // const [imageLoaded, setImageLoaded] = useState(false);
  const { activeDocumentIndex, handleApprove, handleImageClick } =
    useDocumentCard({
      activeID,
      refetch,
      setShowDocumentVerificationModal,
    });
  return (
    <div className={`${Style.root} pro-p-2`}>
      <div className={`${Style.root_image_lists} col-12 pro-mb-3 pro-pb-3`}>
        <div className={`row`}>
          {data?.profileData[activeID] ? (
            <div className={`col-12 ${Style.id_container}`}>
              {data?.profileData[activeID][0]?.documents[activeDocumentIndex]
                ?.split(".")
                .pop() !== "pdf" ? (
                <TransformWrapper>
                  {({ zoomIn, zoomOut }) => (
                    <>
                      <div className={Style.action_icon}>
                        <button
                          className={`${Style.icon} pro-btn pro-p-0 pro-mb-1`}
                          onClick={() => zoomIn()}
                        >
                          <span className="material-symbols-outlined">
                            zoom_in
                          </span>
                        </button>
                        <button
                          className={`${Style.icon} pro-btn pro-p-0`}
                          onClick={() => zoomOut()}
                        >
                          <span className="material-symbols-outlined">
                            zoom_out
                          </span>
                        </button>
                      </div>
                      <TransformComponent>
                        <div className={`${Style.root_image} pro-mb-1`}>
                          <div className={Style.root_figure}>
                            <figure className={`${Style.root_figure} shine ${Style.thumb}`}>
                              <img
                                src={
                                  data?.profileData[activeID][0].documents[
                                    activeDocumentIndex
                                  ] || ""
                                }
                                alt={"profile"}
                                heigh={`100%`}
                                width={`100%`}
                                className={Style.image}
                              />
                            </figure>
                          </div>
                          {/* {!imageLoaded && (
                            <div className={Style.root_figure}>
                              <div className={`shine ${Style.thumb}`} />
                            </div>
                          )}{" "} */}
                        </div>
                      </TransformComponent>
                    </>
                  )}
                </TransformWrapper>
              ) : (
                <DataContainer>
                  <div className="pro-check-box">
                    <p className="pro-check-label">
                      {" "}
                      {data?.profileData[activeID][0]?.original_file_name
                        ?.split(".")
                        .slice(0, -1)
                        .join(".")}
                    </p>
                    <a
                      className="pro-mb-0 pro-pnt"
                      href={`${
                        data?.profileData[activeID][0]?.documents[
                          activeDocumentIndex
                        ] ?? ""
                      }`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      View Uploads
                    </a>
                  </div>
                </DataContainer>
              )}
            </div>
          ) : (
            [...Array(1)].map((_, i) => (
              <div className={`col-12`} key={i}>
                <div className={Style.root_figure}>
                  <div className={`shine ${Style.thumb}`} />
                </div>
                <div
                  className={` pro-d-flex pro-gap-4 pro-p-3 pro-px-4 pro-rounded-3 ${Style.id_proof_shimmer_root}`}
                >
                  <div className="pro-p-3 blink"></div>
                  <div
                    className={`shimmer pro-px-6 pro-align-self-center pro-pb-3`}
                  />
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <div className={`${Style.root_image_lists} col-12 pro-mb-3 pro-pb-3`}>
        <div className={`row`}>
          {data?.profileData[activeID][0]?.documents
            ? data?.profileData[activeID][0]?.documents?.map(
                (profile, index) => (
                  <div
                    className={`col-2`}
                    key={index}
                    onClick={() => handleImageClick(index)}
                  >
                    <div
                      className={`${Style.root_image_inner} ${
                        activeDocumentIndex === index ? Style.active : ""
                      } ${profile.status === 1 ? Style.checked : ""}  ${
                        profile.status === 2 ? Style.rejected : ""
                      }`}
                    >
                      {profile?.split(".").pop() !== "pdf" ? (
                        <Image
                          width={100}
                          height={100}
                          src={profile}
                          alt={`image - 01`}
                        />
                      ) : (
                        <Image
                          width={100}
                          height={100}
                          src={Assets?.PdfLogo}
                          alt={`image - 01`}
                        />
                      )}
                    </div>
                  </div>
                )
              )
            : [...Array(5)].map((_, i) => (
                <div className={`col-2`} key={i}>
                  <div className={Style.root_image_inner}>
                    <div className={`shine ${Style.thumb}`} />
                  </div>
                </div>
              ))}
        </div>
      </div>
      <div
        className={`${Style.buttons} pro-d-flex pro-mt-2 pro-m-1 pro-justify-end`}
      >
        <button
          onClick={() => handleApprove(2)}
          className="pro-btn pro-btn-outline full"
        >
          Reject
        </button>
        <button
          onClick={() =>
            !data?.profileData[activeID][0]?.status && handleApprove(1)
          }
          className={`pro-btn pro-btn-primary full ${
            data?.updateProfileStatus === "pending" ? "loading" : ""
          }`}
        >
          {" "}
          Approve
        </button>
      </div>
    </div>
  );
}
