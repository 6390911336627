import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../Store/Slices/Users/providersSlice";
import * as Yup from "yup";

const useFilterProviders= () => {
  const dispatch = useDispatch();
  const { basicData } = useSelector((state) => state.global);
  const { isEdit} = useSelector((state) => state.providers);
  const validationSchema = Yup.object({
    servicetype: Yup.array(),
    status: Yup.object(),
    language:Yup.array()
  });

  const formik = useFormik({
    initialValues: {
      servicetype:'',
      status: '',
      language:''
    },
    validationSchema,
    onSubmit: (values) => {
        const newvalues = values?.servicetype && values?.servicetype.map(serviceType => serviceType.object_id);
        const newlanguage = values?.language && values?.language.map(Language => Language._id); 
        if(values?.status?.id?.toString()||newlanguage||newvalues){
            dispatch(updateConfig((state) => {
                state.status =  values?.status?.id?.toString()?values?.status?.id?.toString():""
                state.languages = newlanguage
                state.service_type_id =newvalues
              }));
        }
        handleCloseModal()
    },
  });

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleCloseModal = () => {
    dispatch(updateConfig((state) => (state.createFilterModal = false)));
  };

  return {
    formik, isEdit, getFieldError, handleCloseModal ,basicData
  };
};

export default useFilterProviders;
