import React from "react";
import Style from "./notification.module.scss";
import Dropdown from "react-bootstrap/Dropdown";
import { Image } from "@wac-ui-dashboard/wac_component_library";
import Assets from "../../../Assets";
import NotificationListingShimmer from "../Shimmers/NotificationListingShimmer";

const Notification = ({
  data,
  isFetching,
  isNewNotification,
  handleNotificationRedirect,
  handleNotificationsRedirect,
}) => {
  return (
    <Dropdown className="pro-me-4">
      <Dropdown.Toggle id="notification-dropdown">
        <div
          className={`${Style.icon_wrap} ${
            isNewNotification ? Style.active : ""
          }`}
        >
          <span className="material-symbols-outlined x4"> notifications </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className={Style.dropdown} >
        <div className={Style.notification_header}>
          <h5 className={`pro-ttl h5`}>Notifications</h5>
        </div>
        {data
          ?.filter((item) => item.is_read === 0)
          .slice(0, 3)
          ?.map((item, i) => {
            return (
              <React.Fragment key={i}>
                {isFetching ? (
                  <React.Fragment key={i}>
                    <NotificationListingShimmer />
                    <NotificationListingShimmer />
                  </React.Fragment>
                ) : (
                  <Dropdown.Item className={Style.notification_wrap} key={i}>
                    <div
                      className={`${Style.notification_item} ${item?.is_read !== 1 ? Style.un_read : ""} pro-d-flex pro-gap-3`}
                      onClick={() => handleNotificationRedirect(item)}
                      >

                        <div className={`${Style.img_wrap} `}>
                          <Image
                            width={53}
                            height={53}
                            alt={`img`}
                            src={`${item?.image ?? Assets.ADMIN_PROFILE
                              }`}
                          />
                        </div>
                        <div className={Style.notify_msg}>
                          {/* {parse(item?.message)} */}
                          <p>{item?.message}</p>
                          <div className="pro-d-flex pro-items-center pro-mt-2 ">
                            <span className="pro-badge pro-me-2">{item?.label}</span>
                            <span className={`${Style.date} pro-font-xs`}>
                              {item?.received_on}
                            </span>
                          </div>
                        </div>
                      
                      {/* <div className={`${Style.img_wrap} pro-align-self-center pro-me-2`}>
                        <Image
                          width={53}
                          height={53}
                          alt={`img`}
                          src={`${item?.image ?? Assets.GENDER_IMAGE}`}
                        />
                      </div>
                      <div className="col">
                      <div
                        className={`${Style.content} pro-align-self-center pro-d-flex pro-flex-column`}
                      >
                        <p className="pro-mb-1">{item?.title}</p>
                        <span className={Style.date}>{item?.message}</span>
                      </div>
                    <div className="col pro-d-flex">
                        <p>{item?.label}</p>
                        <span className={`${Style.date} pro-ms-auto pro-font-xs`}>
                          {item?.received_on}
                        </span>
                      </div>
                      </div> */}
                      </div>
                  </Dropdown.Item>
                )}
              </React.Fragment>
            );
          })}
        {!isNewNotification  ? (
          <div
            className={`${Style.no_item_msg} pro-d-flex pro-flex-column pro-text-center pro-py-6`}
          >
            <span
              className={`${Style.icon} pro-mx-auto pro-rounded-circle pro-d-flex pro-justify-center`}
            >
              <span className="material-symbols-outlined x4">
                notifications
              </span>
            </span>
            <h6 className="pro-ttl pro-mb-0 pro-mt-5">
              No notification right now
            </h6>
          </div>
        ) : (
          <></>
        )}
        {/* {data?.length > 0 ? ( */}
          <button
            className={`pro-mb-0 pro-w-100 pro-font-sm pro-text-center pro-pb-2 pro-pt-3 text-primary pro-fw-bold ${Style.btn}`}
            onClick={handleNotificationsRedirect}
          >
        View All Notifications
          </button>
        {/* ) : (
          <></>
        )} */}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default Notification;
