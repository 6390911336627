import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../Store/Slices/SubCategory/subcategorySlice";
import { createSubCategory, updateSubCategory } from "../api";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { getBasicData } from "../../../../Store/Slices/Global";

const useAddSubCategory = ({ refetch }) => {
  const dispatch = useDispatch();
  const { isEdit, itemData, itemId } = useSelector((state) => state.subcategory);
  const { basicData } = useSelector((state) => state.global);
  const setBasicData = async () => {
    localStorage?.setItem("basicData", "");
    dispatch(getBasicData());
  };
  const validationSchema = Yup.object({
    name: Yup.string()
      .required("*Name is required")
      .min(3, "Name must be at least 3 characters long")
      .matches(/^[^\s].*$/, "Name must not start with a space"),
    service_type_id: Yup.string().required("*Service type is required"),
    service_category_id: Yup.string().required("*Service type is required"),
  });
  const formik = useFormik({
    initialValues: {
      name: itemData?.name ?? "",
      service_category_id: itemData?.service_category_id ?? "",
      service_type_id: itemData?.service_type_id ?? "",
      status: itemData?.status === 1 ?? false,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      if (isEdit) {
        let data = {
          sub_category_id: itemId,
          name: values.name,
          status: values.status ? 1 : 0,
          service_type_id: values?.service_type_id,
          service_category_id: values?.service_category_id,
        };
        updateSubCategory(data).then((response) => {
          if (response?.status === 200) {
            toast.success("Added");
            setBasicData();
            refetch();
            handleCloseModal();
          } else if (response?.status === 422) {
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
          } else if (response?.success === false) {
            let errors = response?.message;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
          } else {
            toast.error("Something went wrong");
          }
        });
      } else {
        let data = {
          name: values.name,
          service_type_id: values?.service_type_id,
          service_category_id: values?.service_category_id,
        };
        createSubCategory(data).then((response) => {
          if (response?.status === 200) {
            toast.success("Added");
            setBasicData();
            refetch();
            handleCloseModal();
          } else if (response?.status === 422) {
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
          } else if (response?.success === false) {
            let errors = response?.message;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
          } else {
            toast.error("Something went wrong");
          }
        });
      }
    },
  });
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.createModal = false;
        state.itemData = "";
        state.imagePreview = "";
      })
    );
  };
  const handleStatusChange = () => {
    formik.setFieldValue("status", !formik?.values?.status);
  };
  return {
    formik,
    isEdit,
    getFieldError,
    handleCloseModal,
    handleStatusChange,
    basicData,
  };
};

export default useAddSubCategory;
