import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetSeekerPaymentQuery } from "../../../../../Store/Queries/Users/Seekers";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../../../Store/Slices/Global";
import { updateConfig } from "../../../../../Store/Slices/Users/seekerdetailSlice";
import { IconText } from "@wac-ui-dashboard/wac_component_library";

const useSeekerPayments = ({ activeSeeker }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const SeekersState = useSelector((state) => state.seekerdetails);
  const { tableFields } = useSelector((state) => state.global);
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const {
    data: seekerPaymentList = {},
    isLoading,
    isFetching,
  } = useGetSeekerPaymentQuery({
    page_size: SeekersState?.details_paymentPage?.currentPageSize ?? 10,
    current_page: SeekersState?.details_paymentPage?.currentPage ?? 1,
    sort_by: SeekersState?.details_paymentPage?.sortBy ?? "",
    sort_order: SeekersState?.details_paymentPage?.sortOrder ?? "",
    search: SeekersState?.details_paymentPage?.search ?? "",
    user_type:1,
    id:activeSeeker,
  });


  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = seekerPaymentList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [seekerPaymentList]);

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      name: (feild, data) => (
        <div className="pro-pnt text-truncate">
          <IconText
            icon={
              <img
                src={data["profile_image"]}
                alt={"profile-pic"}
                width={20}
                height={20}
                className="object-fit-cover"
              />
            }
            title={data[feild]}
            onClick={() => {
              navigate({
                //   pathname: `/booking/booking-details/${data?._id}`,
              });
            }}
            propStyle={{
              rootIcon:
                "pro-d-flex pro-items-center pro-rounded-circle pro-overflow-hidden",
            }}
          />
        </div>
      ),
      status: (feild, data) => (
        <p
          className={`pro-badge pro-mb-0 ${
            data[feild] === "Blocked"
              ? "badge-success-outline"
              : data[feild] === "Active"
              ? " badge-success-outline"
              : data[feild] === "Inactive"
              ? " badge-warning-outline"
              : data[feild] === "Deleted"
              ? " badge-danger-outline "
              : "badge-outline"
          }`}
        >
          {data[feild]}
        </p>
      ),
      service_type: (field, data) => (
        <p className={`pro-badge badge-outline pro-mb-0`}>{data[feild]}</p>
      ),
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.details_paymentPage.search = e.target.value;
        state.details_paymentPage.currentPage = 1;
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.details_paymentPage.currentPageSize = page_size;
        state.details_paymentPage.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.details_paymentPage.currentPage = page;
      })
    );
  };
  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.details_paymentPage.currentPageSize = 10;
        state.details_paymentPage.currentPage = 1;
        state.details_paymentPage.sortBy = "";
        state.details_paymentPage.sortOrder = "";
        state.details_paymentPage.search = "";
      })
    );
  };

  return {
    isLoading,
    isFetching,
    tableFields,
    seekerPaymentList,
    SeekersState,
    paginationOptions,
    getRow,
    handleSearch,
    handlePageSize,
    handlePagination,
    handleClearClick,
  };
};
export default useSeekerPayments;
