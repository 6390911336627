import { getAxiosInstance } from "../../../API";

export const createSubCategory = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/sub-category/create`, body);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};

export const updateSubCategory  = async (body) => {
  const api = await getAxiosInstance();;
  try {
    const response = await api.put(`/admin/sub-category/update`, body);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};

export const viewSubCategory  = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/admin/sub-category/edit?sub_category_id=${id}`);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};

export const updateStatus = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/sub-category/status-change`, body);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};
export const deleteSubCategory = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.delete(`/admin/sub-category/delete?sub_category_id=${id}`);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};