import React from "react";
import { Link, Outlet } from "react-router-dom";
import ProtectRoute from "../../../../utils/components/ProtectRoute/ProtectRoute";
import {
  Header,
  IconText,
  Image,
  ProfileSideBar,
  SimpleTextDetails,
} from "@wac-ui-dashboard/wac_component_library";
import useCommonLayout from "../../../../Pages/Layouts/CommonLayout/useCommonLayout";
import Assets from "../../../../Assets";
import HeaderActions from "../../../../Pages/Layouts/CommonLayout/HeaderActions";
import Style from "../../../../Pages/Layouts/CommonLayout/commonLayout.module.scss"
import providersStyle from "./providersDetailsLayout.module.scss"
import useProvidersDetailsLayout from "./useProvidersDetailsLayout";
import UsersLayoutSidebarShimmer from "../../../Global/Shimmers/UsersLayoutSidebarShimmer";
import { ReactSVG } from 'react-svg';


const ProvidersDetailsLayout = () => {
  const activeCampaign = []
  const { navigations, globalState } = useCommonLayout();
  const {
    providerID,
    isFetching,
    providerData,
    showAllDesc,
    handleReadMoreDesc,
    handleBackClick } = useProvidersDetailsLayout();
  return (
    <ProtectRoute>
      <Header
        navigations={navigations}
        Link={Link}
        logo={
          globalState.currentTheme === "light" ||
            globalState.currentTheme === null
            ? Assets.HEADER
            : Assets.LOGO_INVERT
        }
        children={<HeaderActions />}
        propStyle={{
          header: Style.header,
          headerInner: Style.header_inner,
        }}
      />

      <div
        className={`pro-w-100 container-fluid pt-custom ${providersStyle.main_wrapper}`}
      >
        <div className={`row gx-0`}>
          {/* side bar left */}
          <div className={`${providersStyle.left_sidebar}`}>
            {isFetching &&
              Object.keys(providerData?.data ?? {})?.length === 0 ? (
              <>
                <UsersLayoutSidebarShimmer/>
              </>
            ) : (
              <>
                <ProfileSideBar
                  extraClassName={providersStyle.left_sidebar_sticky}
                >
                  <div className={`pro-px-5 pro-pt-5`}>
                    <div className={`pro-py-3 pro-border-bottom pro-mb-4`}>
                      <button
                        className="pro-back-btn pro-items-center pro-d-flex"
                        onClick={handleBackClick}
                      >
                        <span className="icon">
                          <span className="material-symbols-outlined x4">
                            keyboard_arrow_left
                          </span>
                        </span>
                        <span className="title"><b>Back </b></span>
                      </button>
                      <SimpleTextDetails
                        title={providerData?.data?.name ?? ""}
                        extraClassNames={`pro-mt-4 pro-mb-2 ${providerData?.data?.profile_name_wrap}`}
                      />
                    </div>
                    <div className={`${providersStyle.left_sidebar_sticky_top}`}>
                      <div className={`${providersStyle.profile}  pro-mb-4 `}>
                        <Image
                          src={`${providerData?.data?.profile_image}`}
                          width={100 || ""}
                          height={100 || ""}
                        />
                        <div className={`pro-d-flex ${providersStyle.overall_rating}`}>
                          <span className="">Overall</span>
                          <span className="icon">
                            <ReactSVG src={Assets.STAR_ICON} />
                          </span>
                          <span className="">{providerData?.data?.rating} </span>
                        </div>
                      </div>
                    </div>
                    {providerData?.data?.service_type?.map((data, index) => (
                      <div key={index}>
                        <div className={`${providersStyle.bio} pro-mb-4 `} style={{backgroundColor:data?.colour}}>
                          <div className=" pro-d-flex pro-justify-between  pro-w-100">
                            <div className={`${providersStyle.icon_text} pro-pnt text-truncate`} >
                              <IconText
                                icon={
                                  <img
                                    src={data?.icon}
                                    alt={"profile-pic"}
                                    width={28}
                                    height={28}
                                    className="object-fit-cover"
                                  />
                                }
                                title={data?.name}
                                propStyle={{
                                  rootIcon:
                                    "pro-d-flex pro-items-center  pro-overflow-hidden",
                                }}
                              />
                              <span className={`${providersStyle.price} pro-d-flex pro-items-center pro-mt-2`}><strong>${data?.price}</strong><span className="pro-lh-1">/day</span> </span>
                            </div>
                            <div className={`${providersStyle.rating} pro-mb-1`}>
                              <div className=" pro-d-flex pro-items-center  ">
                                <span className="icon pro-me-1 ">
                                    <ReactSVG src={Assets.STAR_ICON} />
                                </span>
                                <span className={`${providersStyle.rating_score} `}><strong>{data?.rating}</strong><span  className="pro-lh-1">/5 rating </span></span>
                              </div>
                              <span className={`${providersStyle.review_count} pro-mb-1`}>{data?.review_count?.length > 0 ? `${data?.review_count} Review` : `${data?.review_count}+ Reviews`} </span>
                            </div>
                          </div>
                          <div>
                            
                          </div>
                        </div>
                      </div>
                    ))}

                    <div className={`${providersStyle.left_sidebar_sticky_scrollWrap}`} >
                      <h5 className="pro-ttl h6">Basic Details</h5>
                      <div className={`${providersStyle.basic_dtls_wrap}`}>
                        {providerData?.data?.email && (
                          <div className=" pro-d-flex pro-mb-3">
                            <span className="icon pro-me-2">
                              <span className="material-symbols-outlined x4">
                                mail
                              </span>
                            </span>
                            <span className="">{providerData?.data?.email} </span>
                          </div>
                        )}
                        {providerData?.data?.location && (
                          <div className=" pro-d-flex pro-mb-3">
                            <span className="icon pro-me-2">
                              <span className="material-symbols-outlined x4">
                                location_on
                              </span>
                            </span>
                            <span className="">{providerData?.data?.location} </span>
                          </div>
                        )}
                        {providerData?.data?.experience && (
                          <div className=" pro-d-flex pro-mb-3">
                            <span className="icon pro-me-2">
                              <span className="material-symbols-outlined x4">
                                work
                              </span>
                            </span>
                            <span className="">{providerData?.data?.experience} </span>
                          </div>
                        )}
                        {providerData?.data?.language && (
                          <div className=" pro-d-flex pro-mb-3">
                            <span className="icon pro-me-2">
                              <span className="material-symbols-outlined x4">
                                globe
                              </span>
                            </span>
                            <span className="">{providerData?.data?.language?.join(', ')} </span>
                          </div>
                        )}
                        {providerData?.data?.phone_number && (
                          <div className=" pro-d-flex pro-mb-3">
                            <span className="icon pro-me-2">
                              <span className="material-symbols-outlined x4">
                                call
                              </span>
                            </span>
                            <span className="">{providerData?.data?.phone_number} </span>
                          </div>
                        )}
                      </div>
                    </div>
                    {providerData?.data?.bio && (
                    <div className={`${providersStyle.content_box}  pro-mb-4`}>
                        <>
                          {providerData?.data?.bio?.length > 75 && !showAllDesc ? (
                            <p>
                              {providerData?.data?.bio?.slice(0, 75) +
                                "..."}
                              <span
                                className="pro-pnt"
                                onClick={handleReadMoreDesc}
                              >
                                Read More
                              </span>
                            </p>
                          ) : (
                            <p>{providerData?.data?.bio}</p>
                          )}
                        </> 
                    </div>
                    )}
                  </div>
                </ProfileSideBar>
              </>
            )}
          </div>

          {/* main container */}
          <div className={`${providersStyle.main_container}`}>
            <Outlet context={{ activeCampaign, providerID }} />
          </div>
        </div>
      </div>
    </ProtectRoute>
  );
};

export default ProvidersDetailsLayout;
