import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../Store/Slices/Staff/staffSlice";
import countryData from "../../../../utils/countryCode";
import { createStaff, updateExpertise } from "../api";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { getBasicData } from "../../../../Store/Slices/Global";

const useAddStaff = ({ refetch }) => {
  const dispatch = useDispatch();
  const {isEdit, itemData } = useSelector((state) => state.staff);
  const { basicData ,staffData } = useSelector((state) => state.global);
  const [status, setStatus] = useState(true);
  const setBasicData = async () => {
    localStorage?.setItem("basicData", "");
    dispatch(getBasicData());
  };
  const validationSchema = Yup.object({
    name: Yup.string()
    .required("*Name is required")
    .min(3, "Name must be at least 3 characters long")
    .matches(/^[^\s].*$/, "Name must not start with a space"),
    country_code: Yup.object().required("*Select country"),
    phone_number: Yup.string()
    .required("*Enter mobile number")
    .matches(/^[0-9]+$/, "Number must be digits")
    .min(7, "Number must be at least 7 digits")
    .max(14, "Number must be at most 14 digits"),
    email: Yup.string()
    .email("Invalid email format")
    .required("*Email is required"),
    role: Yup.object().required("*Role is required")
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      country_code: "",
      phone_number:"",
      email:"",
      role:"",
    },
    validationSchema,
    onSubmit: (values) => {
        
      if (isEdit) {
        let data = {
            name:values.name,
            country_code:values.country_code?.value,
            phone_number:values.phone_number,
            role_id:values?.role?._id,
            email:values?.email,
            user_id: itemData?._id,
          };
        updateExpertise(data).then((response) => {
          if (response?.status === 200) {
            toast.success("Added");
            setBasicData()
            refetch();
            handleCloseModal();
          } else if (response?.status === 422) {
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
          } 
          else if (response?.success === false) {
            let errors = response?.message;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            })
          } else {
            toast.error("Something went wrong");
          }
        });
      } else {
        let data = {
            name:values.name,
            country_code:values.country_code?.value,
            phone_number:values.phone_number,
            role_id:values?.role?._id,
            email:values?.email
            // _id: values?.servicetype?.id,
          };
        createStaff(data).then((response) => {
          if (response?.status === 200) {
            toast.success("Added");
            setBasicData();
            refetch();
            handleCloseModal();
          } else if (response?.status === 422) {
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
          }else if (response?.success === false) {
            let errors = response?.message;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            })
         
          }else {
            toast.error("Something went wrong");
          }
        });
      }
    },
  });
  useEffect(() => {
    if (isEdit) {
      Object.keys(itemData || {}).forEach((key) => {
        dispatch(
          updateConfig(
            (state) => (state.imagePreview = itemData?.image)
          )
        );
        if (key ==="name") {
          formik.setFieldValue("name", itemData?.[key]);
        }else if (key ==="email") {
           formik.setFieldValue("email", itemData?.[key]);
        } else if (key ==="phone_number") {
         formik.setFieldValue("phone_number", itemData?.[key]);
         }else if (key === "email") {
            formik.setFieldValue("email", itemData?.[key]);
         } else if (key === "country_code") {
            const country = countryData.find(country=> country.value === itemData?.country_code);
            formik.setFieldValue("country_code", country);
          } else if (key === "role_id") {
          formik.setFieldValue("role", itemData?.[key]);
          itemData?.status === 1 ? setStatus(true) : setStatus(false);
          // itemData?.[key] === 1 ? setStatus(true) : setStatus(false);
        }
      });
    }
    if (!isEdit) {
        formik?.setFieldValue("country_code", {
          name: "Canada",
          code: "CA",
          label: "+1",
          value: "+1",
        });
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, itemData]);


  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.createModal = false;
        state.itemData = "";
        state.imagePreview = "";
      })
    );
  };
  const handleStatusChange = () => {
    setStatus(!status);
  };
  return {
    formik,
    isEdit,
    getFieldError,
    handleCloseModal,
    status,
    handleStatusChange,
    basicData,
    staffData,
    countryCodeOptions: countryData,
  };
};

export default useAddStaff;
