import React from "react";
import Style from "./ProgressBar.module.scss";

const ProgressBar = (props) => {
  const { color, completed, label, percentage, total } = props;

  // Calculate the percentage
  const calculated_percentage = total > 0 ? (completed / total) * 100 : 0;

  return (
    <div className={Style.progressBarContainer}>
      <div className={Style.labelWrapper}>
        <div className={Style.labelLeft}>{label}</div>
        <div className={Style.labelRight}>
          {percentage ? `${completed.toFixed(2)}%` : completed}
        </div>
      </div>
      <div className={Style.progressBarWrapper}>
        <div
          className={Style.filler}
          style={{
            width: `${
              !percentage
                ? calculated_percentage
                : completed > 100
                ? 100
                : completed
            }%`,
            backgroundColor: color,
          }}
        />
      </div>
    </div>
  );
};

export default ProgressBar;
