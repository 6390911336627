import { useSelector } from "react-redux";
import {
  useGetProvidersDataQuery,
  useProvidersTableHeadDataMutation,
} from "../../../Store/Queries/Users/Providers";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateConfig as globalUpdateConfig } from "../../../Store/Slices/Global";
import { updateConfig } from "../../../Store/Slices/Users/providersSlice";
import { viewProviders, deleteProviders, exportOrderReport } from "./api";
import { toast } from "react-toastify";
import { subDays, format } from "date-fns";
import { IconText } from "@wac-ui-dashboard/wac_component_library";
import moment from "moment";

const useProviders = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const providersState = useSelector((state) => state.providers);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  let actionOptions = [{ label: "Edit Columns", value: 0 }];
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const [initialDate, setInitialDate] = useState({
    startDate: subDays(new Date(), 30),
    endDate: subDays(new Date(), 0),
    key: "selection",
  });

  const {
    data: providersList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetProvidersDataQuery({
    page_size: providersState.currentPageSize,
    currentPage: providersState.currentPage,
    sort_by: providersState.sortBy,
    sort_order: providersState.sortOrder,
    start:
      providersState.start === ""
        ? format(initialDate?.startDate, "dd-MM-yyyy")
        : providersState.start,
    end:
      providersState.end === ""
        ? format(initialDate?.endDate, "dd-MM-yyyy")
        : providersState.end,
    search: providersState.search,
    status: providersState?.status,
    service_type_id: providersState?.service_type_id,
    languages: providersState?.languages,
  });
  const [updateStatusFields] = useProvidersTableHeadDataMutation();
  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = providersList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [providersList]);

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      name: (feild, data) => (
        <div className="pro-pnt text-truncate">
          <IconText
            icon={
              <img
                src={data["profile_image"]}
                alt={"profile-pic"}
                width={20}
                height={20}
                className="object-fit-cover"
              />
            }
            title={data[feild]}
            onClick={() => {
              // if (usersList?.data?.permissions?.can_view) {
              navigate({
                pathname: `/users/providers-details/${data?._id}activeTab=documents`,
              });
              // sessionStorage.setItem("active-user", `${data?._id}`);
              // }
            }}
            propStyle={{
              rootIcon:
                "pro-d-flex pro-items-center pro-rounded-circle pro-overflow-hidden",
            }}
          />
        </div>
      ),
      status: (feild, data) => (
        <p
          className={`pro-badge pro-mb-0 ${
            data[feild] === "Blocked"
              ? "badge-success-outline"
              : data[feild] === "Active"
              ? " badge-success-outline"
              : data[feild] === "Inactive"
              ? " badge-warning-outline"
              : data[feild] === "Deleted"
              ? " badge-danger-outline "
              : "badge-outline"
          }`}
        >
          {data[feild]}
        </p>
      ),
      document_verification_status: (feild, data) => (
        <p
          className={`pro-badge pro-mb-0 ${
            data[feild] === "Approved"
              ? " badge-success-outline"
              : data[feild] === "Pending"
              ? " badge-warning-outline"
              : data[feild] === "Rejected"
              ? " badge-danger-outline "
              : "badge-outline"
          }`}
        >
          {data[feild]}
        </p>
      ),
      service_type: (field, data) =>
        data[field]?.map((value, index) => (
          <p
            key={index}
            className={`pro-badge badge-outline pro-m-1`}
          >
            {value}
          </p>
        )),
      language: (field, data) =>
          data[field]?.map((value, index) => (
            <p
              key={index}
              className={`pro-badge badge-outline  pro-m-1`}
            >
              {value}
            </p>
          )),
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = 10;
        state.currentPage = 1;
        state.sortBy = "";
        state.sortOrder = "";
        state.start = format(subDays(new Date(), 30), "dd-MM-yyyy");
        state.end = format(new Date(), "dd-MM-yyyy");
        state.search = "";
        state.status = "";
        state.service_type_id = "";
        state.languages = "";
      })
    );
    setInitialDate({
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: "selection",
    });
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (providersState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = providersState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.isEdit = true;
        state.createModal = true;
        state.itemId = e?.[0];
      })
    );
    viewProviders(e?.[0]).then((response) => {
      if (response?.status === 200) {
        dispatch(
          updateConfig((state) => (state.itemData = response?.data?.data))
        );
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.isEdit = false;
        state.createModal = true;
      })
    );
  };

  const handleCloseClick = () => {
    dispatch(
      updateConfig((state) => {
        state.createModal = false;
        state.itemData = "";
        state.imagePreview = "";
        state.documents = [];
        state.phoneverified = false;
        state.emailverified = false;
        state.formActiveTab = "BasicDetails";
      })
    );
  };
  const handleDeleteAction = () => {
    let id = providersState?.itemId;
    // if(providersState?.itemData?.can_update){
    deleteProviders(id).then((response) => {
      if (response?.status === 200) {
        toast.success("Deleted");
        refetch();
        refetch();
        handleDeleteCloseModal();
        dispatch(updateConfig((state) => (state.clearSelection = true)));
        const clearSelection = setTimeout(() => {
          dispatch(updateConfig((state) => (state.clearSelection = false)));
          clearTimeout(clearSelection);
        }, 500);
      } else {
        toast.error("Something went wrong");
        handleDeleteCloseModal();
      }
    });
    // }else{
    //   toast.error("Dont have an access to Delete");
    // }
  };
  const handleDateChange = (date) => {
    dispatch(
      updateConfig((state) => {
        state.start = format(new Date(date.startDate), "dd-MM-yyyy");
        state.end = format(new Date(date.endDate), "dd-MM-yyyy");
      })
    );
  };
  const handleDeleteCloseModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.deleteModal = !state.deleteModal;
        state.itemId = "";
        state.itemData = "";
      })
    );
  };
  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.deleteModal = !state.deleteModal;
        state.itemId = e?.[0];
      })
    );
    viewProviders(e?.[0]).then((response) => {
      if (response?.status === 200) {
        dispatch(
          updateConfig((state) => (state.itemData = response?.data?.data))
        );
      } else {
        toast.error("Something went wrong");
      }
    });
  };
  const handleFilter = () => {
    dispatch(
      updateConfig((state) => {
        state.isFilter = false;
        state.createFilterModal = true;
      })
    );
  };
  const handleCloseFilter = () => {
    dispatch(updateConfig((state) => (state.createFilterModal = false)));
  };
  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };
  const handleExport = () => {
    exportOrderReport({
      fields: Object.keys(tableFields),
      sort_by: providersState.sortBy,
      sort_order: providersState.sortOrder,
      start:
        providersState.start === ""
          ? format(initialDate?.startDate, "dd-MM-yyyy")
          : providersState.start,
      end:
        providersState.end === ""
          ? format(initialDate?.endDate, "dd-MM-yyyy")
          : providersState.end,
      search: providersState.search,
    }).then((response) => {
      let dataType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      let binaryData = [];
      binaryData.push(response?.data);
      let downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(
        new Blob(binaryData, { type: dataType })
      );
      downloadLink.setAttribute(
        "download",
        `${moment(Date.now()).format("MM_DD_YYYY_HH_mm_ss")}.xlsx`
      );
      document.body.appendChild(downloadLink);
      downloadLink.click();
    });
  };
  return {
    isLoading,
    isFetching,
    tableFields,
    providersList,
    providersState,
    paginationOptions,
    getRow,
    refetch,
    handleSort,
    handleSearch,
    handlePageSize,
    handleEditAction,
    handlePagination,
    handleCloseClick,
    handleClearClick,
    handleCreateClick,
    handleDeleteModal,
    handleDeleteCloseModal,
    handleDeleteAction,
    handleFilter,
    handleCloseFilter,
    actionOptions,
    handleActionChange,
    updateStatusFields,
    showEditModal,
    handleDateChange,
    initialDate,
    handleExport,
  };
};

export default useProviders;
