import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../API";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "",
  start: "",
  end: "",
  search: "",
  status: "",
  service_type_id: "",
  deactivateId:"",
  deactivateModal:false,
  schedule: "",
  createModal: false,
  itemName:"",
  chatModal: false,
  chatStatus: "",
  isEdit: false,
  itemId: "",
  messageData: [],
  messageFullData:[],
  message_status: "idle",
  itemData: "",
  imagePreview: "",
  clearSelection: false,
  createFilterModal: false,
};

export const getMessageList = createAsyncThunk(
  "admin/chat-history",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/admin/chat-history/message-list`, {
        params,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const chatSlice = createSlice({
  name: "chatslice",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },

  // extraReducers: (builder) => {
  //   builder.addCase(getMessageList.pending, (state) => {
  //     state.message_status = "pending";
  //   });
  //   //  builder.addCase(getMessageList.fulfilled, (state, action) => {
  //   //       if (
  //   //         state.messageData?.[action.meta?.arg?.message_id] &&
  //   //         !action.meta?.arg?.clearState
  //   //       ) {
  //   //         if (action.payload?.data?.data?.data) {
  //   //           state.messageData[action.meta?.arg?.message_id] = {
  //   //             ...action.payload?.data?.data,
  //   //             data: [
  //   //               ...state.messageData[action.meta?.arg?.message_id].data,
  //   //               ...action.payload?.data?.data?.data,
  //   //             ],
  //   //           };
  //   //         } else {
  //   //           state.messageData[action.meta?.arg?.message_id] = {
  //   //             ...action.payload?.data?.data,
  //   //             data: [
  //   //               ...state.messageData[action.meta?.arg?.message_id].data,
  //   //             ],
  //   //           };
  //   //         }
  //   //       } else {
  //   //         state.messageData[action.meta?.arg?.message_id] = {
  //   //           ...action.payload?.data?.data,
  //   //           page: 1,
  //   //         };
  //   //       }
  //   //       state.message_status = "fulfilled";
  //   //     });
  //   // builder.addCase(getMessageList.fulfilled, (state, action) => {
  //   //   state.messageData = action.payload.data.data;
  //   //   state.message_status = "fulfilled";
  //   // });
  //   builder.addCase(getMessageList.rejected, (state) => {
  //     state.message_status = "rejected";
  //   });
  // },
  extraReducers: (builder) => {
    builder.addCase(getMessageList.pending, (state) => {
      state.message_status = "pending";
    });
    builder.addCase(getMessageList.fulfilled, (state, action) => {
      state.message_status = "fulfilled";
      const messageData = action.payload.data.data;
      if (messageData?.list && Array.isArray(messageData?.list)) {
        state.messageData = [
          ...state.messageData, 
          ...messageData?.list, 
        ];
      } else {
        state.messageData = messageData?.list;
      }

      state.messageFullData = messageData;
    });
    
    
    builder.addCase(getMessageList.rejected, (state) => {
      state.message_status = "rejected";
    });

    
  },
});

export const { updateConfig } = chatSlice.actions;
export default chatSlice.reducer;
