import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {useGetProviderbookingQuery } from "../../../../../Store/Queries/Users/Providers";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateConfig as globalUpdateConfig} from "../../../../../Store/Slices/Global";
import { updateConfig } from "../../../../../Store/Slices/Users/providerdetailSlice";
import { IconText } from "@wac-ui-dashboard/wac_component_library";

const useProviderBooking = ({activeProvider}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const providersState = useSelector((state) => state.providerdetails);
  const { tableFields} = useSelector((state) => state.global);
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const {
    data: providerBookingList = {},
    isLoading,
    isFetching,
  } = useGetProviderbookingQuery({
    page_size: providersState?.details_bookingPage?.currentPageSize,
    current_page: providersState?.details_bookingPage.currentPage,
    sort_by: providersState?.details_bookingPage.sortBy,
    sort_order: providersState?.details_bookingPage.sortOrder,
    search: providersState?.details_bookingPage.search,
    user_id: activeProvider,

  });
  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = providerBookingList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [providerBookingList]);
  
  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      name: (feild, data) => (
        <div className="pro-pnt text-truncate">
          <IconText
            icon={
              <img
                src={data["profile_image"]}
                alt={"profile-pic"}
                width={20}
                height={20}
                className="object-fit-cover"
              />
            }
            title={data[feild]}
            onClick={() => {
                navigate({
                  pathname: `/booking/booking-details/${data?._id}`,
                });
            }}
            propStyle={{
              rootIcon:
                "pro-d-flex pro-items-center pro-rounded-circle pro-overflow-hidden",
            }}
          />
        </div>
      ),
      status: (feild, data) => (
        <p
          className={`pro-badge pro-mb-0 ${
            data[feild] === "Blocked"
              ? "badge-success-outline"
              : data[feild] === "Active"
              ? " badge-success-outline"
              : data[feild] === "Inactive"
              ? " badge-warning-outline"
              : data[feild] === "Deleted"
              ? " badge-danger-outline "
              : "badge-outline"
          }`}
        >
          {data[feild]}
        </p>
      ),
      service_type: (field, data) => (
        <p className={`pro-badge badge-outline pro-mb-0`}>{data[feild]}</p>
      ),
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.details_bookingPage.search = e.target.value;
        state.details_bookingPage.currentPage = 1;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.details_bookingPage.currentPageSize = page_size;
        state.details_bookingPage.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.details_bookingPage.currentPage = page;
      })
    );
  };
  const handleClearClick = () => {
    dispatch(updateConfig((state) => {
      state.details_bookingPage.currentPageSize= 10;
      state.details_bookingPage.currentPage= 1;
      state.details_bookingPage.sortBy = "";
      state.details_bookingPage.sortOrder = "";
      state.details_bookingPage.search = "";
    }))
  };
  return {
    isLoading,
    isFetching,
    tableFields,
    providerBookingList,
    providersState,
    paginationOptions,
    getRow,
    handleSearch,
    handlePageSize,
    handlePagination,
    handleClearClick
  };
  
};

export default useProviderBooking;
