import {
    Combobox,
    ComboboxInput,
    ComboboxList,
    ComboboxOption,
    ComboboxOptionText,
  } from "@reach/combobox";
  import { useLoadScript } from "@react-google-maps/api";
  import React, { useCallback, useState } from "react";
  import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
  } from "use-places-autocomplete";
  import "@reach/combobox/styles.css";
  import Style from "./googleMap.module.scss";
  import { useDispatch } from "react-redux";
  import { updateConfig } from "../../../../../../Store/Slices/Users/providersSlice";
  import { useSelector } from "react-redux";
  
  const useGoogleMap = ({ formik }) => {
    const dispatch = useDispatch();
    const [libraries] = useState(["places"]);
  
    const { maps, selectedLocation } = useSelector(
      (state) => state.providers
    );
  
    let api_key = process.env.REACT_APP_G_MAPS_KEY;
    let location_url = process.env.REACT_APP_G_MAPS_LOCATION_URL;
  
    const containerStyle = {
      width: "100%",
      height: "387px",
    };
  
    const { isLoaded } = useLoadScript({
      googleMapsApiKey: api_key,
      libraries: libraries,
    });
  
    const onMapClick = useCallback(async (event) => {
      const { latLng } = event;
      try {
        const response = await fetch(
          `${location_url}?latlng=${latLng.lat()},${latLng.lng()}&key=${api_key}`
        );
  
        if (!response.ok) {
          throw new Error("Failed to fetch reverse geocoding data");
        }
  
        const data = await response.json();
  
        if (data.results && data.results.length > 0) {
          const placeName = data.results[0].formatted_address;
          dispatch(
            updateConfig(
              (state) =>
                (state.selectedLocation = {
                  lat: latLng.lat(),
                  lng: latLng.lng(),
                  placeName: placeName,
                })
            )
          );
          dispatch(
            updateConfig(
              (state) =>
                (state.maps = {
                  lat: latLng.lat(),
                  lng: latLng.lng(),
                  place: placeName,
                })
            )
          );
          formik.setFieldValue("address", placeName);
        } else {
          console.error("No results found for reverse geocoding");
        }
      } catch (error) {
        console.error("Error during reverse geocoding:", error);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    const PlacesAutoComplete = () => {
      const {
        ready,
        value,
        setValue,
        suggestions: { status, data },
        clearSuggestions,
      } = usePlacesAutocomplete();
  
      const handleSelect = async (address) => {
        // setValue(address, false);
        // formik.setFieldValue("address", address);
        clearSuggestions();
  
        const results = await getGeocode({ address });
        const { lat, lng } = await getLatLng(results[0]);
  
        try {
          const response = await fetch(
            `${location_url}?latlng=${lat},${lng}&key=${api_key}`
          );
  
          if (!response.ok) {
            throw new Error("Failed to fetch reverse geocoding data");
          }
  
          const data = await response.json();
  
          // let address1 = "";
          // let postcode = "";
  
          // for (const component of results[0]?.address_components) {
          //   // @ts-ignore remove once typings fixed
          //   const componentType = component.types[0];
  
          //   switch (componentType) {
          //     case "street_number": {
          //       address1 = `${component.long_name} ${address1}`;
          //       break;
          //     }
  
          //     case "route": {
          //       address1 += component.short_name;
          //       break;
          //     }
  
          //     case "postal_code": {
          //       postcode = `${component.long_name}${postcode}`;
          //       break;
          //     }
  
          //     case "postal_code_suffix": {
          //       postcode = `${postcode}-${component.long_name}`;
          //       break;
          //     }
          //     case "locality":
          //       document.querySelector("#locality").value = component.long_name;
          //       break;
          //     case "administrative_area_level_1": {
          //       document.querySelector("#state").value = component.short_name;
          //       break;
          //     }
          //     case "country":
          //       document.querySelector("#country").value = component.long_name;
          //       break;
          //     default:
          //       break;
          //   }
          // }
  
          if (data.results && data.results.length > 0) {
            const placeName = data.results[0].formatted_address;
            formik.setFieldValue("address", placeName);
            dispatch(
              updateConfig(
                (state) =>
                  (state.selectedLocation = {
                    lat,
                    lng,
                    placeName,
                  })
              )
            );
            formik?.setFieldError("maps", "");
            dispatch(
              updateConfig(
                (state) =>
                  (state.maps = {
                    lat,
                    lng,
                    place: placeName,
                  })
              )
            );
          } else {
            console.error("No results found for reverse geocoding");
          }
        } catch (error) {
          console.error("Error during reverse geocoding:", error);
        }
        // dispatch(
        //   updateConfig((state) => {
        //     state.maps = {
        //       center: {
        //         lat: lat,
        //         lng: lng,
        //       },
        //       zoom: 7,
        //     };
        //   })
        // );
      };
      return (
        <Combobox className={Style.input_wrap} onSelect={handleSelect}>
          <ComboboxInput
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            disabled={!ready}
            className={`pro-input lg icon-r ${Style.input}`}
            placeholder="Search an address"
          />
          <span className="pro-icon">
            <span className="material-symbols-outlined x4">search</span>
          </span>
          <div className={Style.popup}>
            <ComboboxList>
              {status === "OK" &&
                data.map(({ place_id, description }) => (
                  <ComboboxOption
                    className={Style.option}
                    key={place_id}
                    value={description}
                  >
                    <span className="material-symbols-outlined">location_on</span>
                    <span className={Style.place}>
                      <ComboboxOptionText>{description}</ComboboxOptionText>
                    </span>
                  </ComboboxOption>
                ))}
            </ComboboxList>
          </div>
        </Combobox>
      );
    };
  
    return {
      maps,
      isLoaded,
      containerStyle,
      selectedLocation,
      onMapClick,
      PlacesAutoComplete,
    };
  };
  
  export default useGoogleMap;
  