import React from "react";
import { Link, Outlet } from "react-router-dom";
import ProtectRoute from "../../../../utils/components/ProtectRoute/ProtectRoute";
import {
  Header,
  Image,
  ProfileSideBar,
  SimpleTextDetails,
} from "@wac-ui-dashboard/wac_component_library";
import useCommonLayout from "../../../../Pages/Layouts/CommonLayout/useCommonLayout";
import Assets from "../../../../Assets";
import HeaderActions from "../../../../Pages/Layouts/CommonLayout/HeaderActions";
import Style from "../../../../Pages/Layouts/CommonLayout/commonLayout.module.scss"
import seekersStyle from "./seekersDetailsLayout.module.scss"
import useSeekersDetailsLayout from "./useSeekersDetailsLayout";
import UsersLayoutSidebarShimmer from "../../../Global/Shimmers/UsersLayoutSidebarShimmer";

const SekeersDetailsLayout = () => {
  const { navigations, globalState } = useCommonLayout();
  const {
    seekerID,
    isFetching,
    seekerData,
    showAllDesc,
    handleReadMoreDesc,
    handleBackClick } = useSeekersDetailsLayout();
  return (
    <ProtectRoute>
      <Header
        navigations={navigations}
        Link={Link}
        logo={
          globalState.currentTheme === "light" ||
            globalState.currentTheme === null
            ? Assets.HEADER
            : Assets.LOGO_INVERT
        }
        children={<HeaderActions />}
        propStyle={{
          header: Style.header,
          headerInner: Style.header_inner,
        }}
      />

      <div
        className={`pro-w-100 container-fluid pt-custom ${seekersStyle.main_wrapper}`}
      >
        <div className={`row gx-0`}>
          {/* side bar left */}
          <div className={`${seekersStyle.left_sidebar}`}>
            {isFetching &&
              Object.keys(seekerData?.data ?? {})?.length === 0 ? (
              <>
                <UsersLayoutSidebarShimmer />
              </>
            ) : (
              <>
                <ProfileSideBar
                  extraClassName={seekersStyle.left_sidebar_sticky}
                >
                  <div className={`pro-px-5 pro-pt-5`}>
                    <div className={`pro-py-3 pro-border-bottom  pro-px-2 pro-mb-4`}>
                      <button
                        className="pro-back-btn pro-items-center pro-d-flex"
                        onClick={handleBackClick}
                      >
                        <span className="icon">
                          <span className="material-symbols-outlined x4">
                            keyboard_arrow_left
                          </span>
                        </span>
                        <span className="title"><b>Back </b></span>
                      </button>
                      <SimpleTextDetails
                        title={seekerData?.data?.name ?? ""}
                        extraClassNames={`pro-mt-4 pro-mb-2 ${seekerData?.data?.profile_name_wrap}`}
                      />
                    </div>
                    <div className={`${seekersStyle.left_sidebar_sticky_top}`}>
                      <div className={`${seekersStyle.profile}pro-py-3 pro-px-2  pro-mb-4`}>
                        <Image
                          src={`${seekerData?.data?.profile_image}`}
                          width={100 || ""}
                          height={100 || ""}
                        />
                      </div>
                    </div>
                    {seekerData?.data?.bio && (
                    <div className={`${seekersStyle.bio} pro-py-3 pro-px-2 pro-mb-4`}>
                     
                        <>
                          {seekerData?.data?.bio?.length > 75 && !showAllDesc ? (
                            <p>
                              {seekerData?.data?.bio?.slice(0, 75) +
                                "..."}
                              <span
                                className="pro-pnt"
                                onClick={handleReadMoreDesc}
                              >
                                Read More
                              </span>
                            </p>
                          ) : (
                            <p>{seekerData?.data?.bio}</p>
                          )}
                        </>
                     
                    </div>
                    )}
                    <div className={`${seekersStyle.left_sidebar_sticky_scrollWrap}`} >
                      <h5 className="pro-ttl h6">Basic Details</h5>
                      <div className={`${seekersStyle.basic_dtls_wrap}`}>
                      {seekerData?.data?.email && (
                        <div className=" pro-d-flex pro-mb-3">
                          <span className="icon pro-me-2">
                            <span className="material-symbols-outlined x4">
                              mail
                            </span>
                          </span>
                          <span className="">{seekerData?.data?.email} </span>
                        </div>
                      )}
                      {seekerData?.data?.location && (
                        <div className=" pro-d-flex pro-mb-3">
                          <span className="icon pro-me-2">
                            <span className="material-symbols-outlined x4">
                              location_on
                            </span>
                          </span>
                          <span className="">{seekerData?.data?.location} </span>
                        </div>
                      )}
                      {seekerData?.data?.experience && (
                        <div className=" pro-d-flex pro-mb-3">
                          <span className="icon pro-me-2">
                            <span className="material-symbols-outlined x4">
                              work
                            </span>
                          </span>
                          <span className="">{seekerData?.data?.experience} </span>
                        </div>
                      )}
                      {seekerData?.data?.language?.join(', ') && (
                        <div className=" pro-d-flex pro-mb-3">
                          <span className="icon pro-me-2">
                            <span className="material-symbols-outlined x4">
                              globe
                            </span>
                          </span>
                          <span className="">{seekerData?.data?.language?.join(', ')} </span>
                        </div>
                      )}
                      {seekerData?.data?.phone_number && (
                        <div className=" pro-d-flex pro-mb-3">
                          <span className="icon pro-me-2">
                            <span className="material-symbols-outlined x4">
                              call
                            </span>
                          </span>
                          <span className="">{seekerData?.data?.phone_number} </span>
                        </div>
                      )}
                      </div>
                      {/* <div className="col">
                        <div className=" pro-d-flex">
                          <span className="icon">
                            <span className="material-symbols-outlined x4">
                              mail
                            </span>
                          </span>
                          <span className="">{seekerData?.data?.email} </span>
                        </div>
                        <div className=" pro-d-flex">
                          <span className="icon">
                            <span className="material-symbols-outlined x4">
                              mail
                            </span>
                          </span>
                          <span className="">{seekerData?.data?.location} </span>
                        </div>
                        <div className=" pro-d-flex">
                          <span className="icon">
                            <span className="material-symbols-outlined x4">
                              email
                            </span>
                          </span>
                          <span className="">{seekerData?.data?.language?.join(', ')} </span>
                        </div>
                        <div className=" pro-d-flex">
                          <span className="icon">
                            <span className="material-symbols-outlined x4">
                              email
                            </span>
                          </span>
                          <span className="">{seekerData?.data?.phone_number} </span>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </ProfileSideBar>
              </>
            )}
          </div>

          {/* main container */}
          <div className={`${seekersStyle.main_container}`}>
            <Outlet context={ seekerID } />
          </div>
        </div>
      </div>
    </ProtectRoute>
  );
};

export default SekeersDetailsLayout;
