import { getAxiosInstance } from "../../../API";

export const createServiceType = async (body) => {
  const formData = new FormData();
  Object.keys(body).forEach((key) => {
    formData.append(key, body[key]);
  });
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`admin/service-types/create`, formData);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};

export const updateServiceType = async (body) => {
  const api = await getAxiosInstance();
  const formData = new FormData();
  Object.keys(body).forEach((key) => {
    formData.append(key, body[key]);
  });
  try {
    const response = await api.post(`/admin/service-types/update`, formData);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};

export const viewServiceTypes = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/admin/service-types/edit?service_type_id=${id}`);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};

export const updateStatus = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.put(`/admin/service-types/status/change`, body);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};
export const deleteServiceType = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.delete(`/admin/service-types/delete?service_type_id=${id}`);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};