import React, { forwardRef } from "react";
import { Offcanvas } from "react-bootstrap";
import { IconText } from "@wac-ui-dashboard/wac_component_library";
import Style from "./offCanvasCustom.module.scss";

export const OffCanvasCustom = forwardRef(
  (
    {
      data,
      show,
      className,
      status,
      handleClose,
      title,
      body,
      children,
      headerData,
      ...props
    },
    ref
  ) => {
    return (
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        className={`pro-rounded-start ${Style.offcanvas} ${className}`}
        {...props}
      >
        <Offcanvas.Header className={`${Style.offcanvasHeader}`} closeButton>
          {headerData}
        </Offcanvas.Header>

        <Offcanvas.Body
          ref={ref}
          className={`pro-p-3 pro-bg-light ${Style.offcanvasBody}`}
        >
          {children || body}
        </Offcanvas.Body>
      </Offcanvas>
    );
  }
);
