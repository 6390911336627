import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "",
  start:"",
  end:"",
  search: "",
  status: "",
  service_type_id:[],
  createModal: false,
  isEdit: false,
  itemId:"",
  itemData:"",
  imagePreview:"",
  deleteModal: false,
  clearSelection: false,
  createFilterModal:false,
  phoneverified:false,
  emailverified:false,
  formActiveTab: "BasicDetails",
  maps: {
    lat: 23.4241,
    lng: 53.8478,
    place: "",
  },
  selectedLocation: {
    lat: "",
    lng: "",
    place: "",
  },
};

const SeekersSlice = createSlice({
  name: "seekersslice",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = SeekersSlice.actions;
export default SeekersSlice.reducer;
