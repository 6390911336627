import { getAxiosInstance } from "../../../API";

export const createProviders = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/service-providers/create`, body);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updateProviders = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/service-providers/update`, body, {
      headers: {
        'X-HTTP-Method-Override': 'PUT'
      }
    })
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const viewProviders = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/admin/service-providers/edit?service_provider_id=${id}`);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updateStatus = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.put(`/admin/service-providers/status-change`, body);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
export const deleteProviders = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.delete(`/admin/service-providers/delete?service_provider_id=${id}`);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
export const Otpsend = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/otp/send`, body);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
export const OtpVerification = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/otp/verify`, body);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
export const exportOrderReport = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/service-providers/export`, body,{
      responseType: 'blob' 
    });
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};