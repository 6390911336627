import React from "react";
import Style from "../servicetype.module.scss";
import useServiceTypeFeild from "./useServiceTypeFeild";
import Select from "react-select";
import { Input } from "@wac-ui-dashboard/wac_component_library";
import Accordion from "react-bootstrap/Accordion";

const ServiceTypeFeild = ({
  formik,
  itmIndex,
  getFieldError,
  basicData,
  handleOption,
}) => {
  const { handleRemoveField, setIndex, index, handleOptions } =
    useServiceTypeFeild({ formik, basicData });
  const unique_type = formik?.values?.service_type[itmIndex]?._id?._id;
  const selectedServiceType = basicData?.service_types_list?.find(
    (service) => service._id === unique_type
  );
  const selectedCategories = selectedServiceType?.categories || [];

  return (
    <>
      <div className={`form-wrap-gray `}>
        <Accordion.Header
          className={`accordian-title ${
            formik.values.service_type?.length === 1 ? "single" : ""
          }`}
        >
          Service <span className="count">{itmIndex}</span>
        </Accordion.Header>
        <Accordion.Body className={`pro-pt-5`}>
          <div className="input-wrap pro-mb-4">
            <label
              htmlFor="servicetype"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              Service Name
            </label>
            <Select
              placeholder={"Service Name"}
              classNamePrefix="pro-input"
              options={handleOptions() ?? []}
              getOptionValue={(option) => option?._id}
              getOptionLabel={(option) => option?.name}
              menuPlacement="auto"
              isClearable
              id={`ServiceName${itmIndex}`}
              name={`service_type.${itmIndex}._id`}
              className={`pro-input lg ${
                formik.errors.service_type?.[itmIndex]?._id &&
                formik.touched?.service_type?.[itmIndex]?._id &&
                "error"
              }`}
              value={formik.values.service_type?.[itmIndex]?._id ?? {}}
              onBlur={formik.handleBlur(`service_type.${itmIndex}._id`)}
              onChange={(value) => {
                const selectedIndex = basicData?.service_types_list.findIndex(
                  (option) => option._id === value?._id && value?._id
                );
                setIndex(selectedIndex);
                formik.setFieldValue(`service_type.${itmIndex}._id`, value);
                formik.setFieldValue(`service_type.${itmIndex}.categories`, "");
              }}
            />
            {formik.touched.service_type?.[itmIndex]?._id &&
              formik.errors.service_type?.[itmIndex]?._id && (
                <span className="error-text">
                  {formik.errors.service_type?.[itmIndex]?._id}
                </span>
              )}
          </div>

          <div className="input-wrap pro-mb-4">
            <label
              htmlFor="servicetype"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              Types of Service
            </label>
            <Select
              isMulti={true}
              placeholder={"Service Type"}
              classNamePrefix="pro-input"
              options={selectedCategories ?? []}
              getOptionValue={(option) => option?._id}
              getOptionLabel={(option) => option?.name}
              menuPlacement="auto"
              isClearable
              id={`ServiceTypes${itmIndex}`}
              name={`service_type.${itmIndex}.categories`}
              className={`pro-input lg ${
                formik.errors.service_type?.[itmIndex]?.categories &&
                formik.touched?.service_type?.[itmIndex]?.categories &&
                "error"
              }`}
              value={
                formik.values.service_type?.[itmIndex]?.categories?.[0]?._id ===
                ""
                  ? []
                  : formik.values.service_type?.[itmIndex]?.categories
              }
              onBlur={formik.handleBlur(`service_type.${itmIndex}.categories`)}
              onChange={(selectedCategories) => {
                const existingCategories =
                  formik.values.service_type?.[itmIndex]?.categories || [];
                const updatedCategories = selectedCategories.map((newCat) => {
                  const existingCat = existingCategories.find(
                    (cat) => cat._id === newCat._id
                  );
                  return existingCat
                    ? existingCat
                    : { ...newCat, sub_category: [], expertise: [] };
                });

                formik.setFieldValue(
                  `service_type.${itmIndex}.categories`,
                  updatedCategories
                );
              }}
            />

            {selectedCategories
              ?.filter(
                (selectedCategory) =>
                  Array.isArray(
                    formik.values.service_type?.[itmIndex]?.categories
                  ) &&
                  formik.values.service_type[itmIndex].categories.some(
                    (cat) => cat?._id === selectedCategory?._id
                  )
              )
              .map((category, index) => {
                const subcategories = category?.sub_category || [];
                const expertise = category?.expertise || [];
                const matchedCategory =
                  formik.values.service_type?.[itmIndex]?.categories?.find(
                    (cat) => cat?._id === category._id
                  ) || {};

                return (
                  <div
                    key={category._id}
                    className={`pro-w-100 ${Style.inner_dropdown_wrapper}`}
                  >
                    {subcategories?.length > 0 && (
                      <div>
                        <label
                          htmlFor="servicetype"
                          className="pro-font-sm pro-mb-1 pro-fw-medium"
                        >
                          Subcategories of {category.name}
                        </label>
                        <Select
                          isMulti
                          placeholder={`Subcategories for ${category.name}`}
                          classNamePrefix="pro-input"
                          options={subcategories}
                          getOptionValue={(option) => option?._id}
                          getOptionLabel={(option) => option?.name}
                          menuPlacement="auto"
                          isClearable
                          id={`Subcategories${itmIndex}-${index}`}
                          name={`service_type.${itmIndex}.categories[${index}].sub_category`}
                          className={`pro-input lg ${
                            formik.errors.service_type?.[itmIndex]?._id &&
                            formik.touched?.service_type?.[itmIndex]?._id &&
                            "error"
                          }`}
                          value={matchedCategory.sub_category || []}
                          onChange={(value) => {
                            const updatedCategories =
                              formik.values.service_type[
                                itmIndex
                              ].categories.map((cat) => {
                                if (cat._id === category._id) {
                                  return { ...cat, sub_category: value };
                                }
                                return cat;
                              });

                            formik.setFieldValue(
                              `service_type.${itmIndex}.categories`,
                              updatedCategories
                            );
                          }}
                        />
                        {formik.errors.service_type?.[itmIndex]?.categories?.[
                          index
                        ]?.sub_category &&
                          formik.touched.service_type?.[itmIndex]?.categories?.[
                            index
                          ]?.sub_category && (
                            <span className="error-text">
                              {
                                formik.errors.service_type[itmIndex].categories[
                                  index
                                ].sub_category
                              }
                            </span>
                          )}
                      </div>
                    )}
                    {expertise?.length > 0 && (
                      <div>
                        <label
                          htmlFor="servicetype"
                          className="pro-font-sm pro-mb-1 pro-fw-medium"
                        >
                          Expertise of {category.name}
                        </label>
                        <Select
                          isMulti
                          placeholder={`Expertise for ${category.name}`}
                          classNamePrefix="pro-input"
                          options={expertise}
                          getOptionValue={(option) => option?._id}
                          getOptionLabel={(option) => option?.name}
                          menuPlacement="auto"
                          isClearable
                          id={`Expertise${itmIndex}-${index}`}
                          name={`service_type.${itmIndex}.categories[${index}].expertise`}
                          className={`pro-input lg ${
                            formik.errors.service_type?.[itmIndex]?._id &&
                            formik.touched?.service_type?.[itmIndex]?._id &&
                            "error"
                          }`}
                          value={matchedCategory.expertise || []}
                          onChange={(value) => {
                            const updatedCategories =
                              formik.values.service_type[
                                itmIndex
                              ].categories.map((cat) => {
                                if (cat._id === category._id) {
                                  return { ...cat, expertise: value };
                                }
                                return cat;
                              });

                            formik.setFieldValue(
                              `service_type.${itmIndex}.categories`,
                              updatedCategories
                            );
                          }}
                        />
                        {formik.errors.service_type?.[itmIndex]?.categories?.[
                          index
                        ]?.expertise &&
                          formik.touched.service_type?.[itmIndex]?.categories?.[
                            index
                          ]?.expertise && (
                            <span className="error-text">
                              {
                                formik.errors.service_type[itmIndex].categories[
                                  index
                                ].expertise
                              }
                            </span>
                          )}
                      </div>
                    )}
                  </div>
                );
              })}
            {formik.touched.service_type?.[itmIndex]?.categories &&
              formik.errors.service_type?.[itmIndex]?.categories && (
                <span className="error-text">
                  {formik.errors.service_type?.[itmIndex]?.categories}
                </span>
              )}
          </div>

          <div className="input-wrap pro-mb-4">
            <Input
              label={"Price"}
              placeholder={"Price"}
              type="text"
              maxLength={6}
              onKeyDown={(e) => {
                const regex = /^[0-9]$/;
                const allowedKeys = [
                  "Backspace",
                  "ArrowLeft",
                  "ArrowRight",
                  "Tab",
                  "Delete",
                ];
                if (!regex.test(e.key) && !allowedKeys.includes(e.key)) {
                  e.preventDefault();
                }
              }}
              id={`ServicePrice${itmIndex}`}
              name={`service_type.${itmIndex}.price`}
              className={`pro-input pro-md-100 lg ${
                formik.errors.service_type?.[itmIndex]?.price &&
                formik.touched?.service_type?.[itmIndex]?.price &&
                "error"
              }`}
              value={formik.values.service_type?.[itmIndex]?.price || ""}
              onBlur={formik.handleBlur(`service_type.${itmIndex}.price`)}
              onChange={(e) =>
                formik.setFieldValue(
                  `service_type.${itmIndex}.price`,
                  e.target.value
                )
              }
              error={
                formik.touched.service_type?.[itmIndex]?.price &&
                formik.errors.service_type?.[itmIndex]?.price && (
                  <span className="error-text">
                    {formik.errors.service_type?.[itmIndex]?.price}
                  </span>
                )
              }
              errorMessage={
                formik.touched.service_type?.[itmIndex]?.price &&
                formik.errors.service_type?.[itmIndex]?.price && (
                  <span className="error-text">
                    {formik.errors.service_type?.[itmIndex]?.price}
                  </span>
                )
              }
            />
          </div>
          <div className="input-wrap">
            <Input
              label={"Experience"}
              placeholder={"Experience"}
              type="text"
              maxLength={6}
              onKeyDown={(e) => {
                const regex = /^[0-9]$/;
                const allowedKeys = [
                  "Backspace",
                  "ArrowLeft",
                  "ArrowRight",
                  "Tab",
                  "Delete",
                ];
                if (!regex.test(e.key) && !allowedKeys.includes(e.key)) {
                  e.preventDefault();
                }
              }}
              id={`ServiceExperience${itmIndex}`}
              name={`service_type.${itmIndex}.experience`}
              className={`pro-input pro-md-100 lg ${
                formik.errors.service_type?.[itmIndex]?.experience &&
                formik.touched?.service_type?.[itmIndex]?.experience &&
                "error"
              }`}
              value={formik.values.service_type?.[itmIndex]?.experience || ""}
              onBlur={formik.handleBlur(`service_type.${itmIndex}.experience`)}
              onChange={(e) =>
                formik.setFieldValue(
                  `service_type.${itmIndex}.experience`,
                  e.target.value
                )
              }
              error={
                formik.touched.service_type?.[itmIndex]?.experience &&
                formik.errors.service_type?.[itmIndex]?.experience && (
                  <span className="error-text">
                    {formik.errors.service_type?.[itmIndex]?.experience}
                  </span>
                )
              }
              errorMessage={
                formik.touched.service_type?.[itmIndex]?.experience &&
                formik.errors.service_type?.[itmIndex]?.experience && (
                  <span className="error-text">
                    {formik.errors.service_type?.[itmIndex]?.experience}
                  </span>
                )
              }
            />
          </div>
          {formik.values.service_type?.length > 1 && (
            <div
              className={`${Style.delete_btn} pro-justify-center pro-d-flex pro-items-center`}
            >
              <span
                className="material-symbols-outlined x4"
                onClick={() => handleRemoveField(itmIndex)}
              >
                delete
              </span>
            </div>
          )}
        </Accordion.Body>
      </div>
    </>
  );
};

export default ServiceTypeFeild;
