import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import Style from "./Providerdoccard.module.scss";
import { DataContainer, Image } from "@wac-ui-dashboard/wac_component_library";
import { useState } from "react";
import Assets from "../../../../../../Assets";

export function ProviderDocCard({
  data,
  index,
  setIndex,
}) {
  const [imageLoaded, setImageLoaded] = useState(false);
  return (
    <div className={`${Style.root} pro-p-2`}>
      <div className={`${Style.root_image_lists} col-12 pro-mb-3 pro-pb-3`}>
        <div className={`row`}>
          {data[index] ? (
            <div className={`col-12 ${Style.id_container}`}>
              {data[index]?.url?.split(".").pop() !== "pdf" ? (
                <TransformWrapper>
                  {({ zoomIn, zoomOut }) => (
                    <>
                      <div className={Style.action_icon}>
                        <button
                          className={`${Style.icon} pro-btn pro-p-0 pro-mb-1`}
                          onClick={() => zoomIn()}
                        >
                          <span className="material-symbols-outlined">
                            zoom_in
                          </span>
                        </button>
                        <button
                          className={`${Style.icon} pro-btn pro-p-0`}
                          onClick={() => zoomOut()}
                        >
                          <span className="material-symbols-outlined">
                            zoom_out
                          </span>
                        </button>
                      </div>
                      <TransformComponent>
                        <div className={`${Style.root_image} pro-mb-1`}>
                          <div className={Style.root_figure}>
                            <figure className={`${Style.image}`}>
                              <img
                                src={data[index]?.url || ""}
                                alt={"profile"}
                                heigh={`100%`}
                                width={`100%`}
                                onLoad={() => setImageLoaded(true)}
                              />
                            </figure>
                          </div>
                          {!imageLoaded && (
                            <div className={Style.root_figure}>
                              <div className={`shine ${Style.thumb}`} />
                            </div>
                          )}{" "}
                        </div>
                      </TransformComponent>
                    </>
                  )}
                </TransformWrapper>
              ) : (
                <DataContainer>
                  <div className="pro-check-box">
                    <p className="pro-check-label">
                      {" "}
                      {data[index]?.name}
                    </p>
                    <a
                      className="pro-mb-0 pro-pnt"
                      href={`${data[index]?.url ?? ""}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      View Uploads
                    </a>
                  </div>
                </DataContainer>
              )}
            </div>
          ) : (
            [...Array(1)].map((_, i) => (
              <div className={`col-12`} key={i}>
                <div className={Style.root_figure}>
                  <div className={`shine ${Style.thumb}`} />
                </div>
                <div
                  className={` pro-d-flex pro-gap-4 pro-p-3 pro-px-4 pro-rounded-3 ${Style.id_proof_shimmer_root}`}
                >
                  <div className="pro-p-3 blink"></div>
                  <div
                    className={`shimmer pro-px-6 pro-align-self-center pro-pb-3`}
                  />
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <div className={`${Style.root_image_lists} col-12 pro-mb-3 pro-pb-3`}>
        <div className={`row`}>
          {data
            ? data?.map((profile, i) => (
                <div
                  className={`col-2`}
                  key={i}
                  onClick={() => setIndex(i)}
                >
                  <div
                    className={`${Style.root_image_inner} `}
                  >
                    <Image
                      src={
                        profile?.url?.split(".").pop() !== "pdf"
                          ? `${profile?.url}`
                          : Assets.PdfLogo
                      }
                      width={100 || ""}
                      height={100 || ""}
                    />
                  </div>
                </div>
              ))
            : [...Array(5)].map((_, i) => (
                <div className={`col-2`} key={i}>
                  <div className={Style.root_image_inner}>
                    <div className={`shine ${Style.thumb}`} />
                  </div>
                </div>
              ))}
        </div>
      </div>
    </div>
  );
}
