import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "",
  search: "",
  status: "",
  service_type_id:"",
  createModal: false,
  isEdit: false,
  itemId:"",
  itemData:"",
  deleteModal: false,
  clearSelection: false,
  createFilterModal:false,
};

const CategorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = CategorySlice.actions;
export default CategorySlice.reducer;
