import {
    ConfirmationBox,
    HeadingGroup,
    ModalLayout,
    Pagination,
    SearchFilters,
    OffCanvasLayout,
    Table
  } from "@wac-ui-dashboard/wac_component_library";
  import React from "react";
  import { FaSort } from "react-icons/fa";
  import EmptyData from "../../Global/EmptyData";
  import OrderColumn from "../../Global/OrderColumn";
import useCategory from "./useCategory";
import AddCategory from "./AddCategory";
import FilterCategory from "./FilterCategory";
  
  const Category = () => {
    const {
      isLoading,
      isFetching,
      tableFields,
      categoryList,
      categoryState,
      paginationOptions,
      getRow,
      refetch,
      handleSort,
      handleSearch,
      handlePageSize,
      handleEditAction,
      handlePagination,
      handleCloseClick,
      handleClearClick,
      handleCreateClick,
      handleDeleteModal,
      handleDeleteCloseModal,
      handleDeleteAction,
      handleFilter,
      handleCloseFilter,
      actionOptions,
      handleActionChange,
      updateCategoryFields,
      showEditModal
    } = useCategory();
    return (
      <div>
        <HeadingGroup
          title={"Category"}
          extraClassName={`pro-mb-1 heading-wrap`}
          buttonTitle={categoryList?.data?.permissions?.can_create && "Create"}
          handleClick={handleCreateClick}
        />
        <div className="col-auto pro-pt-3 pro-pb-6">
          <div className="pro-d-flex search-filter-wrap">
            <SearchFilters
              onSearchInput={handleSearch}
              showActions={false}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              // showClearFilters
              // handleClear={handleClearClick}
              searchInputProps={{ value: categoryState?.search }}
              actionOptions={actionOptions?.filter((item) => item.value === 0)}
              onActionOptionChange={handleActionChange}
              extraFilters={
                <>
                <div className="col-auto">            
                  <button
                    className={"pro-btn pro-items-center pro-btn-outline pro-mx-1"}
                    onClick={handleFilter}
                  >
                    <span className="material-symbols-outlined x4">tune</span>
                    Filter
                  </button>
                </div>
                <div className="col-auto pro-d-flex">           
                  <button
                    className={" pro-btn-link lg"}
                    onClick={handleClearClick}
                  >                 
                    Clear
                  </button>
                </div>
                </>
              }
            />
  
          </div>
          {categoryList?.data?.data?.length !== 0 ? (
            <>
              <Table
                multiSelect={false}
                data={categoryList?.data?.data || []}
                uniqueID={"_id"}
                fields={tableFields}
                SortIcon={<FaSort />}
                editIcon={<span className="material-symbols-outlined">edit</span>}
                deleteIcon={
                  <span className="material-symbols-outlined">delete</span>
                }
                handleDelete={handleDeleteModal}
                handleEdit={handleEditAction}
                handleSort={handleSort}
                clear={categoryState.clearSelection}
                getRow={getRow}
                loading={isFetching}
                perpage={categoryState?.currentPageSize}
                editable={categoryList?.data?.permissions?.can_update}
                deletable={categoryList?.data?.permissions?.can_delete}
                assignable={false}
                showCheckBox={categoryList?.data?.permissions?.can_update}
              />
              {categoryList?.data?.data?.length > 0 && (
                
                <Pagination
                  currentPage={categoryState?.currentPage}
                  totalPageCount={Math.ceil(
                    categoryList?.data?.total /
                    categoryState.currentPageSize
                  )}
                  options={paginationOptions}
                  onPageChange={handlePagination}
                  onActionChange={handlePageSize}
                  center
                />
              )}
            </>
          ) : (
            <EmptyData />
          )}
         <OffCanvasLayout
            show={categoryState?.createModal}
            handleClose={handleCloseClick}
            title={categoryState?.isEdit ? "Edit Category" : "Add Category"}
            closeicon={<span className="material-symbols-outlined">close</span>}
            backdrop="static"
          >
            <AddCategory refetch={refetch} />
          </OffCanvasLayout>
          <OffCanvasLayout
            show={categoryState?.createFilterModal}
            handleClose={handleCloseFilter}
            title={"Filter"}
            closeicon={<span className="material-symbols-outlined">close</span>}
            backdrop="static"
          >
            <FilterCategory refetch={refetch} />
          </OffCanvasLayout>
          <ModalLayout
            show={categoryState?.deleteModal}
            handleClose={handleDeleteCloseModal}
          >
            <div className="pro-m-5">
              <ConfirmationBox
                title={"Are you sure you want to delete?"}
                subTitle={"This action cannot be undo "}
                cancelText={"No"}
                cancelAction={handleDeleteCloseModal}
                submitText={"Yes"}
                submitAction={handleDeleteAction}
                isRight={true}
              />
            </div>
          </ModalLayout>
          <ModalLayout show={showEditModal} handleClose={handleCloseClick}>
            <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              tableFields={categoryList?.data?.fields}
              moduleId={categoryList?.data?.module_id}
              updateData={updateCategoryFields} 
              refetch={refetch}
            />
            </div>
          </ModalLayout>
        </div>
      </div>
    );
  };
  
  export default Category;
  