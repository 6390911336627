import { useSelector } from "react-redux";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useMemo } from "react";

const useCMS = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const globalState = useSelector((state) => state.global);
  const drawerMenu = [
    {
      title: "FAQ",
      label: "FAQ",
      link: "/CMS/FAQ",
      iconAsset: "DrawerIcon1",
      icon: (
        <span className="material-symbols-outlined x4">verified</span>
      ),
      active: checkIfActiveRoute("/CMS/FAQ"),
    },
    {
        title: "Privacy Policy",
        label: "Privacy Policy",
        link: "/CMS/privacypolicy",
        iconAsset: "DrawerIcon1",
        icon: (
          <span className="material-symbols-outlined x4">chat_add_on</span>
        ),
        active: checkIfActiveRoute("/CMS/privacypolicy"),
      },
      {
        title: "Terms and Conditions",
        label: "Terms and Conditions",
        link: "/CMS/termsandconditions",
        iconAsset: "DrawerIcon1",
        icon: (
          <span className="material-symbols-outlined x4">task</span>
        ),
        active: checkIfActiveRoute("/CMS/termsandconditions"),
      },
  ];
  const drawerMenuPermission = useMemo(() => {
    let menus = Object.values(
      globalState.menu_permissions ?? []
    )?.flatMap?.((menu) =>
      menu?.permissions?.map((side_menu) => side_menu?.sub_menu_name)
    );

    return drawerMenu
      ?.filter((menu) => {
        return menus?.includes?.(menu?.label);
      })
      ?.map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.menu_permissions, location.pathname]);

  return {
    drawerMenu: drawerMenuPermission,
  };
};

export default useCMS;
