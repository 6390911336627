import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getNotificationData,
  updateConfig,
} from "../../../Store/Slices/Notifications/notificationSlice";
import { notifications } from "../../../Store/Queries/Notifications";
// import { updateConfig as updateSupportConfig } from "../../../Store/Slices/Support/suppportSlice";
import { useSelector } from "react-redux";
import useObserver from "../../../utils/hooks/useObserver";
import { useNavigate } from "react-router-dom";
import { MarkNotificationRead } from "../../../Pages/Layouts/CommonLayout/HeaderActions/api";

const useNotificationListing = () => {
  const [showScroll, setShowScroll] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const state = useSelector((state) => state.notifications);
  const notificationState = state?.notificationData?.["notificationData"];
  useEffect(() => {
    if (Object.keys(state?.notificationData)?.length === 0) {
      dispatch(
        getNotificationData({
          page: page,
          notification_id: "notificationData",
        })
      );
    }
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    function handleWindowResize() {
      if (window.scrollY > 150) {
        setShowScroll(true);
      } else if (window.scrollY === 0) {
        setShowScroll(false);
      }
    }
    window.addEventListener("scroll", handleWindowResize);

    return () => {
      window.removeEventListener("scroll", handleWindowResize);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleLoadMore = () => {
    dispatch(
      updateConfig((state) => {
        state.notificationData["notificationData"].page += 1;
      })
    );
    setPage((prev) => prev + 1);
    dispatch(
      getNotificationData({
        page: page + 1,
        notification_id: "notificationData",
      })
    );
  };
  const { lastElement } = useObserver({
    loading: state?.notificationStatus === "pending",
    hasMore:
      notificationState?.items_per_page * notificationState?.current_page <
      notificationState?.total_count,
    callback: handleLoadMore,
  });

  const handleRedirect = async (item) => {
    const url = item?.redirection_url;
    const id = item?._id;
    let data = {
      notification_id: id,
    };
    dispatch(
      updateConfig((state) => {
        state.notificationData={};
      })
    );
    MarkNotificationRead(data).then(() => {
      dispatch(
        getNotificationData({
          page: 1,
          notification_id: "notificationData",
        })
      );
      dispatch(notifications.util.invalidateTags(["notifications"]));
    });
    navigate(`/${url}`);
  };

  return {
    showScroll,
    state,
    notificationState,
    lastElement,
    handleRedirect,
    handleScrollToTop,
  };
};

export default useNotificationListing;
