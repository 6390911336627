import React from 'react'
import { Outlet } from 'react-router-dom'
import CommonLayout from '../../Pages/Layouts/CommonLayout';
import useBooking from './useBooking';

const Booking = () => {
  const {drawerMenu}=useBooking()
  return (
    <CommonLayout drawerMenu={drawerMenu}>
    <Outlet></Outlet>
  </CommonLayout>
  )
}

export default Booking
