import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../API";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "",
  start:"",
  end:"",
  search: "",
  status: "",
  createModal: false,
  isEdit: false,
  itemId:"",
  itemData:"",
  imagePreview:"",
  deleteModal: false,
  clearSelection: false,
  createFilterModal:false,
  profileData: {},
  updateProfileStatus: "idle",
};

export const updateVerificationDataStatus = createAsyncThunk(
    "updateVerificationDataStatus",
    async (params, { rejectWithValue }) => {
      const api = await getAxiosInstance();
      try {
        const response = await api.post(
          `/admin/verification/process`,
          params
        );
  
        return response;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );
const VerificationSlice = createSlice({
  name: "verificationslice",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    extraReducers: {
        // [getVerificationData.pending]: (state) => {
        //     state.profileDataStatus = "pending";
        //   },
        // [getVerificationData.fulfilled]: (state, action) => {
        //     const userID =
        //       JSON.parse(action.payload?.config?.data)?.user_id || "user_id";
      
        //     state.profileDataStatus = "success";
      
        //     state.profileData = {
        //       ...state.profileData,
        //       [userID]: action.payload.data?.data?.data,
        //     };
      
        //     state.profileRejectResponse =
        //       action.payload?.data?.data?.rejection_reasons || [];
        //   },
        //   [getVerificationData.rejected]: (state) => {
        //     state.profileDataStatus = "failed";
        //   },
        [updateVerificationDataStatus.pending]: (state) => {
          state.updateProfileStatus = "pending";
        },
        [updateVerificationDataStatus.fulfilled]: (state, action) => {
          state.updateProfileStatus = "success";
    
          // const userID = JSON.parse(action.payload?.config?.data)?.user_id;
          // const imageID = JSON.parse(action.payload?.config?.data)?.upload_id;
          // const status = JSON.parse(action.payload?.config?.data)?.status;
    
          // const imageIndex = state.profileData[userID].findIndex(
          //   (img) => img._id === imageID
          // );
    
          // state.profileData[userID][imageIndex].status = status;
    
          // toast.success("Status updated successfully");
        },
        [updateVerificationDataStatus.rejected]: (state) => {
          state.updateProfileStatus = "failed";
        },
      },
  },
});


export const { updateConfig } = VerificationSlice.actions;
export default VerificationSlice.reducer;
