import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  OffCanvasLayout,
  Table,
  ActionBtns,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import EmptyData from "../../Global/EmptyData";
import OrderColumn from "../../Global/OrderColumn";
import useChat from "./useChat";
import FilterChat from "./FilterChat";
import { OffCanvasCustom } from "../../Global/OffCanvasCustom";
import ChatMessages from "./ChatMessages";
import StatusCard from "./ChatStatus";
import Style from "./chatStatus.module.scss";

const Chat = () => {
  const {
    isLoading,
    isFetching,
    tableFields,
    chatsList,
    chatsState,
    paginationOptions,
    getRow,
    refetch,
    handleSort,
    handleSearch,
    handlePageSize,
    handlePagination,
    handleCloseClick,
    handleClearClick,
    handleCreateClick,
    handleDeleteModal,
    handleDeactivate,
    // handleFilter,
    handleDeleteCloseModal,
    handleDeleteAction,
    handleCloseFilter,
    actionOptions,
    handleActionChange,
    updateStatusFields,
    showEditModal,
    handleDateChange,
    initialDate,
    handleCloseModal,
    lastElement,
    handleOpenDeactivateModal,
    handleCloseDeactivateModal,
  } = useChat();
  return (
    <div>
      <HeadingGroup
        title={"Chat"}
        className={`pro-mb-4`}
        buttonTitle={chatsList?.message?.permissions?.can_create && "Create"}
        handleClick={handleCreateClick}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <div className="pro-d-flex search-filter-datepicker-wrap search_filter_shimmer">
          <SearchFilters
            onSearchInput={handleSearch}
            showActions={false}
            loading={isLoading}
            SearchIcon={
              <span className="material-symbols-outlined"> search </span>
            }
            // showClearFilters
            // handleClear={handleClearClick}
            searchInputProps={{ value: chatsState?.search }}
            actionOptions={actionOptions?.filter((item) => item.value === 0)}
            onActionOptionChange={handleActionChange}
            isDateRange
            showDateRange={true}
            initialDateRange={initialDate}
            onDateChange={handleDateChange}
            extraFilters={
              <>
                {/* <div className="col-auto">          
                <button
                  className={"pro-btn pro-items-center pro-btn-outline pro-mx-1"}
                  onClick={handleFilter}
                >
                  <span className="material-symbols-outlined x4">tune</span>
                  Filter
                </button>
              </div> */}
                <div className="col-auto pro-d-flex">
                  <button
                    className={" pro-btn-link lg"}
                    onClick={handleClearClick}
                  >
                    Clear
                  </button>
                </div>
              </>
            }
          />
        </div>
        {chatsList?.data?.data?.length !== 0 ? (
          <>
            <Table
              multiSelect={false}
              data={chatsList?.data?.data || []}
              uniqueID={"_id"}
              fields={tableFields}
              SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              handleDelete={handleDeleteModal}
              handleSort={handleSort}
              clear={chatsState.clearSelection}
              getRow={getRow}
              loading={isFetching}
              perpage={chatsState?.currentPageSize}
              editable={false}
              deletable={chatsList?.data?.permissions?.can_delete}
              assignable={false}
              showCheckBox={chatsList?.data?.permissions?.can_delete}
            />
            {chatsList?.message?.data?.length > 0 && (
              <div className="pagination-fill">
                <Pagination
                  currentPage={chatsState?.currentPage}
                  totalPageCount={Math.ceil(
                    chatsList?.message?.total / chatsState.currentPageSize
                  )}
                  options={paginationOptions}
                  onPageChange={handlePagination}
                  onActionChange={handlePageSize}
                  center
                />
              </div>
            )}
          </>
        ) : (
          <EmptyData />
        )}
        <OffCanvasLayout
          show={chatsState?.createFilterModal}
          handleClose={handleCloseFilter}
          title={"Filter"}
          closeicon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
        >
          <FilterChat refetch={refetch} />
        </OffCanvasLayout>
        <ModalLayout
          show={chatsState?.deleteModal}
          handleClose={handleDeleteCloseModal}
          closeIcon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
          propStyle={{ root: Style.modal_root }}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={"Are you sure you want to delete?"}
              subTitle={"This action cannot be undo "}
              cancelText={"No"}
              cancelAction={handleDeleteCloseModal}
              submitText={"Yes"}
              submitAction={handleDeleteAction}
              isRight={true}
            />
          </div>
        </ModalLayout>
        <ModalLayout show={showEditModal} handleClose={handleCloseClick}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              tableFields={chatsList?.data?.fields}
              moduleId={chatsList?.data?.module_id}
              updateData={updateStatusFields}
              refetch={refetch}
            />
          </div>
        </ModalLayout>
        <OffCanvasCustom
          data={chatsState}
          show={chatsState?.chatModal}
          handleClose={handleCloseModal}
          title={chatsState?.itemId}
          status={chatsState?.chatStatus}
          closeicon={<span className="material-symbols-outlined">close</span>}
          headerData={
            <StatusCard
              data={chatsState}
              title={chatsState?.itemName}
              status={chatsState?.chatStatus}
            />
          }
          backdrop="static"
        >
          <ChatMessages data={chatsState} lastElement={lastElement} />
          {!chatsState?.messageFullData?.can_deactive && (
            <div className={`pro-px-5 sticky-bottom ${Style.stickyFooter}`}>
              <ActionBtns
                cancelText={"Cancel"}
                submitText={"Deactivate"}
                cancelAction={handleCloseModal}
                submitAction={handleOpenDeactivateModal}
                isRight={true}
              ></ActionBtns>
            </div>
          )}
        </OffCanvasCustom>
        <ModalLayout
          centered={true}
          show={chatsState?.deactivateModal}
          handleClose={handleCloseDeactivateModal}
          backdrop="static"
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={"Are you sure you want to Deactivate?"}
              subTitle={"This action cannot be undo "}
              cancelText={"Cancel"}
              cancelAction={handleCloseDeactivateModal}
              submitText={"Deactivate"}
              submitAction={handleDeactivate}
              isRight={true}
            />
          </div>
        </ModalLayout>
      </div>
    </div>
  );
};

export default Chat;
