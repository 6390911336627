import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../Store/Slices/CMS/faqSlice";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { createFaq, updateFaq } from "../api";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useAddFAQ = ({ closeModal, refetch }) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(true);
  const { isEdit, itemData } = useSelector((state) => state.faq);
  const Options = [
    { label: "Provider", value: 2 },
    { label: "Seeker", value: 1 }
  ];
  
  const formik = useFormik({
    initialValues: {
      question: "",
      user_type:"",
      answer: "",
    },
    validate: (values) => {
      let errors = {};
      const Title = values?.question;
      if (!Title) {
        errors.question = "*Question cannot be empty";
      }
      if (!values?.answer) {
        errors.answer = "*Answer cannot be empty";
      }
      if (!values?.user_type) {
        errors.user_type = "*UserType cannot be empty";
      }
      return errors;
    },

    onSubmit: async (values) => {
      if (!isEdit) {
        let obj = {
          user_type:formik?.values?.user_type,
          question: formik?.values?.question,
          answer: formik?.values?.answer,
        };
        createFaq(obj).then((response) => {
          if (response?.status === 200) {
            dispatch(
              updateConfig((state) => {
                state.editModal = false;
              })
            );
            toast.success("created");
            closeModal();
            refetch();
          } else {
            toast.error("Something went wrong");
          }
        });
      } else {
        let obj = {
          faq_id: itemData?._id,
          question: formik?.values?.question,
          user_type:formik?.values?.user_type,
          answer: formik?.values?.answer,
          status: status ? 1 : 0,
        };
        updateFaq(obj).then((response) => {
          if (response?.status === 200) {
            dispatch(
              updateConfig((state) => {
                state.editModal = false;
              })
            );
            closeModal();
            toast.success("updated");
            refetch();
          } else {
            toast.error("Something went wrong");
          }
        });
      }
    },
  });
  useEffect(() => {
    if (itemData) {
      formik.setFieldValue("answer", itemData?.answer ?? "");
      formik.setFieldValue("question", itemData?.question ?? "");
      formik.setFieldValue("user_type", itemData?.user_type ?? "");
      setStatus(itemData?.status === 1 ? true : false);
    }
    //eslint-disable-next-line
  }, [itemData]);

  const handleContentChange = (value) => {
    formik.setFieldValue("answer", value);
  };
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  const handleStatusChange = () => {
    setStatus(!status);
  };
  return {
    Options,
    formik,
    handleContentChange,
    getFieldError,
    isEdit,
    handleStatusChange,
    status,
  };
};

export default useAddFAQ;
