import React from "react";
import useGoogleMaps from "./useGoogleMaps";
import { GoogleMap, Marker } from "@react-google-maps/api";
import Style from "./googleMaps.module.scss";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../../Store/Slices/Users/seekersSlice";

const GoogleMaps = ({ formik }) => {
  const dispatch = useDispatch();
  const {
    maps,
    isLoaded,
    containerStyle,
    selectedLocation,
    onMapClick,
    PlacesAutoComplete,
  } = useGoogleMaps({ formik });

  return (
    <div>
      {isLoaded ? (
        <div className={Style.root} style={containerStyle}>
          <PlacesAutoComplete />
          <GoogleMap
            mapContainerClassName={Style.map_wrap}
            center={{
              lat: maps?.lat,
              lng: maps?.lng,
            }}
            zoom={7}
            onClick={onMapClick}
            options={{
              streetViewControl: false,
              fullscreenControl: false,
              mapTypeControl: false,
              zoomControl: false,
            }}
          >
            <div>
              {selectedLocation?.lat && (
                <Marker
                  position={{
                    lat: selectedLocation.lat,
                    lng: selectedLocation.lng,
                  }}
                  onClick={() => {
                    dispatch(
                      updateConfig(
                        (state) => (state.selectedLocation = selectedLocation)
                      )
                    );
                  }}
                />
              )}
            </div>
          </GoogleMap>
        </div>
      ) : (
        <p>Loading ...</p>
      )}
    </div>
  );
};

export default GoogleMaps;
