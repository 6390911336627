import React from "react";
import Style from "./chatMessages.module.scss";

const ChatMessages = ({ data, lastElement }) => {
  return (
    <div
      style={{
        height: !data?.messageFullData?.can_deactive
          ? "calc(100vh - 160px)"
          : "calc(100vh - 99px)",
      }}
      className={`pro-chat-container ${Style.proChatContainer}`}
    >
      {Array.isArray(data?.messageData) &&
        data?.messageData?.map((msg, index) => (
          <div
            key={index}
            className={`pro-d-flex ${
              msg.is_seeker ? "pro-ms-auto" : "pro-me-auto"
            } ${Style.ChatMessageContainer}`}
            ref={
              index === data?.messageData?.length - 9
                ? lastElement
                : false || null
            }
          >
            {!msg.is_seeker && (
              <div className="pro-d-flex pro-align-items-center pro-mr-2">
                <img
                  src={msg.service_provider.image}
                  alt={"Provider-pic"}
                  width={24}
                  height={24}
                  className="pro-rounded-circle pro-object-fit-cover"
                />
              </div>
            )}
            <div
              className={`pro-p-2 pro-px-3 pro-rounded pro-bg-${
                msg.is_seeker ? "primary" : "secondary"
              } pro-text-white ${
                msg.is_seeker
                  ? `${Style.ChatMessage} ${Style.seeker}`
                  : `${Style.ChatMessage}`
              }`}
            >
              <span
                className={`${Style.chatDate}`}
                style={{
                  left: msg.is_seeker ? "auto" : 0,
                  right: msg.is_seeker ? 0 : "auto",
                }}
              >
                {msg?.date}
              </span>
              {msg.message}
            </div>
            {msg.is_seeker && (
              <div className="pro-d-flex pro-align-items-center pro-ml-2">
                {/* <span className="pro-mr-2 pro-font-weight-bold">
                  {msg.service_user.name}
                </span> */}
                <img
                  src={msg.service_user.image}
                  alt={"User-pic"}
                  width={24}
                  height={24}
                  className="pro-rounded-circle pro-object-fit-cover"
                />
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default ChatMessages;
