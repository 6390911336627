import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useServices from "./useServices";
import ServiceTypeFeild from "./ServiceTypeFeild";
import Accordion from "react-bootstrap/Accordion";
const Services = ({
  formik,
  getFieldError,
  handleMoveToErrorTab,
  basicData,
}) => {
  const { handleAddService, handleCloseModal, handleNext, handleAccordionToggle, activeKey } = useServices({
    formik,
    handleMoveToErrorTab,
    basicData,
  });

  return (
    <div className="pro-d-flex pro-flex-column pro-h-100">
      <div className="pro-mb-4">
        <Accordion
          activeKey={activeKey}
          flush
          onSelect={handleAccordionToggle}
          className={`form-accrodion ${ formik.values.service_type?.length === 1 ? "single" : "" }`}
        >
          {formik?.values?.service_type?.map((value, index) => (
            <Accordion.Item
              eventKey={index.toString()}
              className={`tasks pro-mb-3`}
              key={index}
              id={index}
            >
              <ServiceTypeFeild
                formik={formik}
                values={value}
                key={index}
                getFieldError={getFieldError}
                itmIndex={index}
                basicData={basicData}
              />
            </Accordion.Item>
          ))}
        </Accordion>
        <button
          className={"pro-btn pro-btn-outline pro-mt-3 pro-items-center lg"}
          onClick={handleAddService}
          type="button"
          disabled={
            (formik?.values?.service_type.length ===
              basicData?.service_types_list.length ||
              (formik?.errors?.service_type &&
                formik?.errors?.service_type?.length !== 0)) &&
            true
          }
        >
          <span className="material-symbols-outlined">add_circle</span>{" "}
          <span>Add Service</span>
        </button>
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end pro-pt-3  pro-mt-5 ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          onClick={handleNext}
          type="submit"
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default Services;
