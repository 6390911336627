import {
  HeadingGroup,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import EmptyData from "../../../../Global/EmptyData";
import useProviderPayments from "./useProviderPayments";

const ProviderPayments = ({ activeProvider }) => {
  const {
    isLoading,
    isFetching,
    tableFields,
    providerPaymentList,
    providersState,
    paginationOptions,
    getRow,
    handleSearch,
    handlePageSize,
    handlePagination,
    handleClearClick,
  } = useProviderPayments({ activeProvider });
  return (
    <div>
      <div className="col-auto pro-pt-3 pro-pb-6">
        <div className="pro-d-flex pro-justify-start">
          <HeadingGroup
            title={"Payments"}
            extraClassName={`pro-mb-1 pro-me-3 heading-wrap pro-w-auto`}
          />
          <SearchFilters
          onSearchInput={handleSearch}
          showActions={false}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          searchInputProps={{ value: providersState?.details_paymentPage?.search }}
          extraFilters={
            <>
            <div className="col-auto pro-d-flex">    
              <button
                className={" pro-btn-link lg"}
                onClick={handleClearClick}
              >        
                Clear
              </button>
            </div>
            </>
          }
        />
        </div>
        {providerPaymentList?.data?.data?.length !== 0 ? (
        <>
          <Table
            multiSelect={false}
            data={providerPaymentList?.data?.data || []}
            uniqueID={"_id"}
            fields={tableFields}
            getRow={getRow}
            loading={isFetching}
            perpage={providersState?.currentPageSize}
            assignable={false}
          />
          {providerPaymentList?.data?.data?.length > 0 && (

            <Pagination
              currentPage={providersState?.currentPage}
              totalPageCount={Math.ceil(
                providerPaymentList?.data?.count /
                providersState?.details_paymentPage?.currentPageSize,
              )}
              options={paginationOptions}
              onPageChange={handlePagination}
              onActionChange={handlePageSize}
              center
            />
          )}
        </>
      ) : (
        <EmptyData />
      )}
      </div>
    </div>
  );
};

export default ProviderPayments;
