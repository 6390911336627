import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Style from "./addFAQ.module.scss";
import useAddFAQ from "./useAddFAQ";
import Select from "react-select";
// import Assets from "../../../../Assets";

const AddFAQ = ({ closeModal, refetch }) => {
  const { formik, getFieldError, isEdit, handleStatusChange, status, Options } =
    useAddFAQ({
      closeModal,

      refetch,
    });

  return (
    <div className="pro-p-5">
      <div className={Style.editor_container}>
        <div className="pro-mb-4">
          <label
            htmlFor="user_type"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            User Type
          </label>
          <Select
            id="user_type"
            // placeholder="User Type"
            className={`pro-input lg ${
              getFieldError("user_type") ? "error" : ""
            }`}
            classNamePrefix="pro-input"
            name="user_type"
            options={Options}
            value={Options.find(
              (option) => option.value === formik.values.user_type
            ) ?? ""}
            onBlur={formik.handleBlur("user_type")}
            onChange={(selectedOption) =>
              formik.setFieldValue("user_type", selectedOption?.value ?? "")
            }
            menuPlacement="auto"
            isClearable
          />

          {getFieldError("user_type") && (
            <span className="error-text">{getFieldError("user_type")}</span>
          )}
        </div>
        <Input
          label={"Question*"}
          type="text"
          id="question"
          name="question"
          className={`pro-input lg ${getFieldError("question") && "error"}`}
          value={formik?.values?.question ?? ""}
          {...formik.getFieldProps("question")}
          error={getFieldError("question")}
          errorMessage={getFieldError("question")}
        />
        <div className="col-12 pro-mb-4">
          <div className="input-wrap ">
            <label
              htmlFor="answer"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              Answer*
            </label>
            <textarea
              id="answer"
              rows={3}
              name="answer"
              className={`pro-input lg`}
              {...formik.getFieldProps("answer")}
              value={formik?.values?.answer ?? ""}
              // onChange={(e) => formik.setFieldValue("answer", e.target.value)}
            ></textarea>
          </div>
        </div>
        {isEdit && (
          <div className="pro-toggle">
            <div className="pro-toggle-box ">
              <input
                type="checkbox"
                role="switch"
                id="status"
                checked={status}
                onChange={handleStatusChange}
              />
              <span></span>
            </div>
            <label htmlFor="status">Status</label>
          </div>
        )}
        {formik.touched.answer && formik.errors.answer && (
          <span className={Style.error_text}>{formik.errors.answer}</span>
        )}
      </div>
      <div className="pro-d-flex pro-justify-end pro-pt-4">
        <button
          className={"pro-btn-link lg "}
          onClick={() => closeModal()}
          type="button"
        >
          Cancel
        </button>
        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default AddFAQ;
