import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useAddStaff from "./useAddStaff";
import Select from "react-select";

const AddStaff = ({ refetch }) => {
  const { formik, isEdit, getFieldError, handleCloseModal,status,handleStatusChange,staffData,countryCodeOptions} = useAddStaff({
    refetch,
  });
  return (
    <div className="col">
      <div className="pro-mb-4">
      <Input
        label={"Name"}
        type="text"
        id="name"
        name="name"
        className={`pro-input lg ${getFieldError("name") && " error"}`}
        {...formik.getFieldProps("name")}
        error={getFieldError("name")}
        errorMessage={getFieldError("name")}
      />
      </div>
      <div className="input-wrap pro-mb-4 ">
        <div className="input-country-wrap ">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Contact Number
          </label>
          <div className="input-main-wrap verify-btn-wrap">
            <div className="code-wrap pro-mb-4 ">
              <Select
                id="country_code"
                options={countryCodeOptions}
                getOptionLabel={(option) => `${option.label}`}
                getOptionValue={(option) => `${option.value}`}
                value={formik?.values?.country_code}
                classNamePrefix="pro-input"
                className="country-select "
                onBlur={formik.handleBlur("country_code")}
                menuPlacement="auto"
                onChange={(value) =>
                  formik.setFieldValue("country_code", value)
                }
              ></Select>
            </div>
            <input
              type="text"
              id="phone_number"
              name="phone_number"
              className={`pro-input lg ${
                formik.errors.phone_number && formik.touched.phone_number && " error"
              }`}
              {...formik.getFieldProps("phone_number")}
              onKeyDown={(e) => {
                const regex = /^[0-9]$/;
                const allowedKeys = [
                  "Backspace",
                  "ArrowLeft",
                  "ArrowRight",
                  "Tab",
                  "Delete",
                ];
                if (!regex.test(e.key) && !allowedKeys.includes(e.key)) {
                  e.preventDefault();
                }
              }}
            />
          </div>
          {formik.errors.phone_number && formik.touched.phone_number && (
            <span className="error-text">{formik.errors.phone_number}</span>
          )}
        </div>
      </div>
      <div className="pro-mb-4">
      <Input
        label={"Email"}
        type="text"
        id="email"
        name="email"
        className={`pro-input lg ${getFieldError("email") && " error"}`}
        {...formik.getFieldProps("email")}
        error={getFieldError("email")}
        errorMessage={getFieldError("email")}
      />
      </div>
      <div className="pro-mb-4">
        <label htmlFor="servicetype" className="pro-font-sm pro-mb-1 pro-fw-medium">
         Role
        </label>
        <Select
          id="role"
          placeholder={"Role"}
          className={`pro-input lg  ${getFieldError("role") && " error"}`}
          classNamePrefix="pro-input"
          name="role"
          options={staffData?? []}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={formik?.values?.role}
          onBlur={formik.handleBlur("role")}
          onChange={(value) => formik.setFieldValue("role", value ?? "")}
          menuPlacement="auto"
          isClearable
        />
        {getFieldError("role") && (
          <span className="error-text">{getFieldError("role")}</span>
        )}
      </div>
      {isEdit &&<div className="pro-toggle">
        <div className="pro-toggle-box ">
          <input
            type="checkbox"
            role="switch"
            id="status"
            checked={status}
            onChange={handleStatusChange}
          />
          <span></span>
        </div>
        <label htmlFor="status">Status</label>
      </div>}

      <div
        className={`col-12 pro-d-flex pro-justify-end ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>

        <Button
          type="submit"
          className={"pro-btn-primary lg pro-ms-3"}
          onClick={formik.handleSubmit}
        >
          {isEdit ? "Update" : "Add"}
        </Button>
      </div>
    </div>
  );
};

export default AddStaff;
