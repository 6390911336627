import { useSelector } from "react-redux";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useMemo } from "react";

const useBooking = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const globalState = useSelector((state) => state.global);
  const drawerMenu = [
    {
      title: "Bookings",
      label: "Bookings",
      link: "/booking/bookings",
      iconAsset: "DrawerIcon1",
      icon: (
        <span className="material-symbols-outlined x4">verified</span>
      ),
      active: checkIfActiveRoute("/booking/bookings"),
    },
    {
        title: "Chat",
        label: "Chat",
        link: "/booking/chat",
        iconAsset: "DrawerIcon1",
        icon: (
          <span className="material-symbols-outlined x4">chat</span>
        ),
        active: checkIfActiveRoute("/booking/chat"),
      },
  ];
  const drawerMenuPermission = useMemo(() => {
    let menus = Object.values(
      globalState.menu_permissions ?? []
    )?.flatMap?.((menu) =>
      menu?.permissions?.map((side_menu) => side_menu?.sub_menu_name)
    );

    return drawerMenu
      ?.filter((menu) => {
        return menus?.includes?.(menu?.label);
      })
      ?.map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.menu_permissions, location.pathname]);

  return {
    drawerMenu: drawerMenuPermission,
  };
};

export default useBooking;
