import {
    HeadingGroup,
    Pagination,
    SearchFilters,
    Table
  } from "@wac-ui-dashboard/wac_component_library";
  import React from "react";
  import EmptyData from "../../../../Global/EmptyData";
  import useSeekerPosts from "./useSeekerPosts";
  
  const SeekerPosts = ({ activeSeeker }) => {
    const {
      isLoading,
      isFetching,
      tableFields,
      seekerPosts,
      seekerState,
      paginationOptions,
      getRow,
      handleSearch,
      handlePageSize,
      handlePagination,
      handleClearClick,
    } = useSeekerPosts({ activeSeeker });
    return (
      <div className="pro-p-3">
        
        <div className="col-auto pro-pt-3 pro-pb-6">
          <div className="pro-d-flex pro-justify-start">
          <HeadingGroup
              title={"Posts"}
              extraClassName={`pro-mb-1 pro-me-3 heading-wrap pro-w-auto`}
            />
            <SearchFilters
              onSearchInput={handleSearch}
              showActions={false}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              searchInputProps={{ value: seekerState?.search }}
              extraFilters={
                <>
                <div className="col-auto pro-d-flex">    
                  <button
                    className={" pro-btn-link lg"}
                    onClick={handleClearClick}
                  >        
                    Clear
                  </button>
                </div>
                </>
              }
            />
  
          </div>
          {seekerPosts?.data?.data?.length !== 0 ? (
            <>
              <Table
                multiSelect={false}
                data={seekerPosts?.data?.data || []}
                uniqueID={"_id"}
                fields={tableFields}
                getRow={getRow}
                loading={isFetching}
                perpage={seekerState?.currentPageSize}
                assignable={false}
              />
              {seekerPosts?.data?.data?.length > 0 && (
  
                <Pagination
                  currentPage={seekerState?.currentPage}
                  totalPageCount={Math.ceil(
                    seekerPosts?.data?.total /
                    seekerState.details_posts?.currentPageSize
                  )}
                  options={paginationOptions}
                  onPageChange={handlePagination}
                  onActionChange={handlePageSize}
                  center
                />
              )}
            </>
          ) : (
            <EmptyData />
          )}
        </div>
      </div>
    );
  };
  
  export default SeekerPosts;
  