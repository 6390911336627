import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateVerificationDataStatus } from "../../../../Store/Slices/Users/verificationSlice";
import { toast } from "react-toastify";

const useDocumentCard = ({
  activeID,
  refetch,
  setShowDocumentVerificationModal,
}) => {
  const dispatch = useDispatch();
  const [activeDocumentIndex, setActiveDocumentIndex] = useState(0);


  const handleApprove = (value) => {
    dispatch(
      updateVerificationDataStatus({
        user_id: activeID,
        status: value,
      })
    )
      .unwrap()
      .then((result) => {
        if (result?.data?.success === true) {
          toast.success(result?.data?.message)
          setShowDocumentVerificationModal(false);
          refetch?.();
        }else{
          toast.error("Something went wrong")
          setShowDocumentVerificationModal(false);
          refetch?.();
        }
      });
  };
  const handleImageClick = (index) => {
    setActiveDocumentIndex(index)
  };
  return {
    activeDocumentIndex,
    handleApprove,
    handleImageClick
  };
};

export default useDocumentCard;
