import style from "./SingleCard.module.scss";
const SingleCard = ({ title, data, icon }) => {
  return (
    <div className={`${style.root} `}>
      <div className={`${style.inner}  pro-flex-column pro-w-100`}>
        <div
          className={`${style.body} pro-d-flex pro-justify-between pro-items-center pro-w-100  pro-flex-row`}
        >
          <div className="title-wrap">
            <h4 className="pro-ttl h4 pro-mb-1 pro-fw-bold">
              {data?.current_month}
            </h4>
            <div className="pro-d-flex ">
              <h6 className="pro-ttl h6 pro-mb-0 pro-fw-medium">{title}</h6>
              <span
                className={`${
                  data?.comparison_status === "greater"
                    ? style.increment
                    : style.decrement
                } ${
                  style.growth_percentage
                } pro-align-self-center pro-font-xs pro-fw-medium pro-badge`}
              >
                <span>{data?.percentage_difference}%</span>
                <span className={`material-symbols-outlined ${style.arrow}`}>
                  arrow_outward
                </span>
              </span>
            </div>
          </div>
          <div className={`${style.icon}`}>
            <span className="material-symbols-outlined">{icon}</span>
          </div>
        </div>
        <div
          className={`pro-d-flex pro-items-center pro-pt-2 pro-mt-auto pro-gap-3`}
        >
          <span className={`pro-badge ${style.badge}`}>{`Previous : ${data?.previous_month}`}</span>
        </div>
      </div>
    </div>
  );
};

export default SingleCard;
