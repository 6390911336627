import { useNavigate, useParams } from "react-router-dom";
import { useGetBookingdetailsQuery } from "../../../../Store/Queries/Booking";

const useBookingDetailsLayout = () => {
  const { bookingID } = useParams();
  const activeBooking =bookingID?.replace(/activeTab=[^&]+/, "");
  const navigate = useNavigate();
  const { data: bookingdetailData, isFetching } = useGetBookingdetailsQuery({
    service_request_id: activeBooking,
  });
  const handleBackClick = () => {
    navigate("/booking/bookings");
  };
  return {
    isFetching,
    bookingdetailData,
    handleBackClick
  };
};

export default useBookingDetailsLayout;
