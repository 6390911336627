import React from "react";
import { IconText } from "@wac-ui-dashboard/wac_component_library";
import Style from "./chatStatus.module.scss";

const StatusCard = ({ data, title, status }) => {
  // Define badge class based on the status
  const getBadgeClass = (status) => {
    switch (status) {
      case "Completed":
        return "badge-success-outline";
      case "Accepted":
        return "badge-success-outline";
      case "Pending":
        return "badge-warning-outline";
      case "Rejected":
        return "badge-danger-outline";
      default:
        return "badge-outline";
    }
  };
  return (
    <div
      className={`pro-d-flex pro-flex-column pro-gap-1.5 ${Style.headerContainer}`}
    >
      <div className={`pro-d-flex pro-gap-3 ${Style.chatHeaderMain}`}>
        <h3 className="pro-mb-0 pro-ttl h3">{title}</h3>
        <p className={`pro-badge pro-mb-0 ${getBadgeClass(status)}`}>
          {status}
        </p>
      </div>

      <div className={`pro-d-flex ${Style.chatHeaderSub}`}>
        {data?.messageData?.[0]?.service_provider && (
          <IconText
            icon={
              <img
                src={data?.messageData?.[0]?.service_provider.image}
                alt="Service Provider"
                width={32}
                height={32}
                className="object-fit-cover pro-rounded-circle"
              />
            }
            title={data?.messageData?.[0]?.service_provider.name}
            propStyle={{ rootIcon: "pro-d-flex pro-items-center" }}
          />
        )}

        {data?.messageData?.[0]?.service_user && (
          <IconText
            icon={
              <img
                src={data.messageData?.[0].service_user.image}
                alt="Service User"
                width={32}
                height={32}
                className="object-fit-cover pro-rounded-circle"
              />
            }
            title={data.messageData?.[0].service_user.name}
            propStyle={{ rootIcon: "pro-d-flex pro-items-center" }}
          />
        )}
      </div>
    </div>
  );
};

export default StatusCard;
