import { useSelector } from "react-redux";
import { useGetSeekersDataQuery,useSeekersTableHeadDataMutation } from "../../../Store/Queries/Users/Seekers";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getStaffData, updateConfig as globalUpdateConfig } from "../../../Store/Slices/Global";
import { updateConfig } from "../../../Store/Slices/Users/seekersSlice";
import { viewSeeker,deleteSeeker,exportOrderReport} from "./api";
import { toast } from "react-toastify";
import { subDays,format} from "date-fns";
import { IconText } from "@wac-ui-dashboard/wac_component_library";
import moment from "moment";

const useSeeker = () => {
  const dispatch = useDispatch();
  const navigate=useNavigate()
  const seekersState = useSelector((state) => state.seekers);
  const { tableFields,showEditModal} = useSelector((state) => state.global);
  let actionOptions = [{ label: "Edit Columns", value: 0 }];
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const [initialDate, setInitialDate] = useState({
    startDate: subDays(new Date(), 30),
    endDate: subDays(new Date(), 0),
    key: "selection",
  });
  const {
    data: seekersList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetSeekersDataQuery({
    page_size: seekersState.currentPageSize,
    currentPage: seekersState.currentPage,
    sort_by: seekersState.sortBy,
    sort_order: seekersState.sortOrder,
    start: seekersState.start===""? format(initialDate?.startDate, 'dd-MM-yyyy'):seekersState.start,
    end: seekersState.end===""? format(initialDate?.endDate, 'dd-MM-yyyy'):seekersState.end,
    search: seekersState.search,
    status:seekersState?.status,
    service_type_id:seekersState?.service_type_id
  });
  const [updateStatusFields] = useSeekersTableHeadDataMutation();
  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = seekersList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [seekersList]);
  useEffect(() => {
   dispatch(getStaffData());
  }, [dispatch]);

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      name: (feild, data) => (
        <div className="pro-pnt text-truncate">
          <IconText
            icon={
              <img
                src={data["profile_image"] }
                alt={"profile-pic"}
                width={20}
                height={20}
                className="object-fit-cover"
              />
            }
            title={data[feild]}
            onClick={() => {
                navigate({
                  pathname: `/users/seeker-details/${data?._id}activeTab=bookings`,
                });
            }}
            propStyle={{
              rootIcon:
                "pro-d-flex pro-items-center pro-rounded-circle pro-overflow-hidden",
            }}
          />
        </div>
      ),
      status: (feild, data) => (
        <p
          className={`pro-badge pro-mb-0 ${
            data[feild] === "Blocked"
              ? "badge-success-outline"
              : data[feild] === "Active"
              ? " badge-success-outline"
              : data[feild] === "Inactive"
              ? " badge-warning-outline"
              : data[feild] === "Deleted"
              ? " badge-danger-outline "
              : "badge-outline"
          }`}
        >
          {data[feild]}
        </p>
      ),
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => {state.search = ""
      state.currentPageSize= 10;
      state.currentPage= 1;
      state.sortBy = "";
      state.sortOrder = "";
      state.start= format(subDays(new Date(), 30), 'dd-MM-yyyy');
      state.end= format(new Date(), 'dd-MM-yyyy');
      state.search = "";
      state.status = "";
      state.service_type_id = "";
    }));
    setInitialDate({
        startDate: subDays(new Date(), 30),
        endDate: new Date(),
        key: "selection",
      });
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (seekersState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = seekersState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.isEdit = true;
        state.createModal = true;
        state.itemId = e?.[0];
      })
    );
    viewSeeker(e?.[0]).then((response) => {
      if (response?.status === 200) {
        dispatch(
          updateConfig((state) => (state.itemData = response?.data?.data))
        );
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.isEdit = false;
        state.createModal = true;
      })
    );
  };

  const handleCloseClick = () => {
    dispatch(
      updateConfig((state) => {
          state.createModal = false;
          state.itemData = "";
          state.imagePreview = "";
          state.phoneverified = false;
          state.emailverified = false;
      })
  );
  };
  const handleDeleteAction = () => {
    let id = seekersState?.itemId;
    // if(seekersState?.itemData?.can_update){
        deleteSeeker(id).then((response) => {
        if (response?.status === 200) {
          toast.success("Deleted");
          refetch();          refetch();
          handleDeleteCloseModal();
          dispatch(updateConfig((state) => (state.clearSelection = true)));
          const clearSelection = setTimeout(() => {
            dispatch(updateConfig((state) => (state.clearSelection = false)));
            clearTimeout(clearSelection);
          }, 500);
        } else {
          toast.error("Something went wrong");
          handleDeleteCloseModal()
        }
      });
    // }else{
    //   toast.error("Dont have an access to Delete");
    // }
  };
  const handleDateChange = (date) => {
    dispatch(
      updateConfig((state) => {
        state.start = format(new Date(date.startDate),'dd-MM-yyyy');
        state.end = format(new Date(date.endDate),'dd-MM-yyyy');
      })
    );
  };
  const handleDeleteCloseModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.deleteModal = !state.deleteModal;
        state.itemId = ''
        state.itemData=''
      })
    );
  };
  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.deleteModal = !state.deleteModal;
        state.itemId = e?.[0];
      })
    );
    viewSeeker(e?.[0]).then((response) => {
      if (response?.status === 200) {
        dispatch(
          updateConfig((state) => (state.itemData = response?.data?.data))
        );
      } else {
        toast.error("Something went wrong");
      }
    });
  };
  const handleFilter = () => {
    dispatch(
      updateConfig((state) => {
        state.isFilter = false;
        state.createFilterModal = true;
      })
    );
  };
  const handleCloseFilter = () => {
    dispatch(updateConfig((state) => (state.createFilterModal = false)));
  };
  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };
  const handleExport=()=>{
    exportOrderReport({
      fields: Object.keys(tableFields),
      sort_by: seekersState.sortBy,
      sort_order: seekersState.sortOrder,
      start: seekersState.start===""? format(initialDate?.startDate, 'dd-MM-yyyy'):seekersState.start,
      end: seekersState.end===""? format(initialDate?.endDate, 'dd-MM-yyyy'):seekersState.end,
      search: seekersState.search,
      status:seekersState?.status,
    }).then((response) => {
      let dataType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      let binaryData = [];
      binaryData.push(response?.data);
      let downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(
        new Blob(binaryData, { type: dataType })
      );
      downloadLink.setAttribute(
        "download",
        `${moment(Date.now()).format("MM_DD_YYYY_HH_mm_ss")}.xlsx`
      );
      document.body.appendChild(downloadLink);
      downloadLink.click();
    });
  }
  return {
    isLoading,
    isFetching,
    tableFields,
    seekersList,
    seekersState,
    paginationOptions,
    getRow,
    refetch,
    handleSort,
    handleSearch,
    handlePageSize,
    handleEditAction,
    handlePagination,
    handleCloseClick,
    handleClearClick,
    handleCreateClick,
    handleDeleteModal,
    handleDeleteCloseModal,
    handleDeleteAction,
    handleFilter,
    handleCloseFilter,
    actionOptions,
    handleActionChange,
    updateStatusFields,
    showEditModal,
    handleDateChange,
    initialDate,
    handleExport
  };
  
};

export default useSeeker;
