import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useBasicdetails from "./useBasicdetails";
import Select from "react-select";
import GoogleMapsbasic from "./GoogleMap";
import FileUpload from "../../../../Global/FileUpload";

const Basicdetails = ({
  formik,
  getFieldError,
  refetch,
  providersState,
  handleCloseModal,
  status,
  setStatus,
}) => {
  const {
    isEdit,
    handleStatusChange,
    basicData,
    countryCodeOptions,
    otpverify,
    setOtpnumber,
    otpnumber,
    otpreq,
    submitname,
    numberChange,
    otpemail,
    otpreqemail,
    otpverifyemail,
    submitemail,
    setOtpemail,
    handleNext,
    handleProfileChange,
    imagePreview,
    handleProfileRemove,
    otpnumbershown,
    otpemailshown,
    handlePhoneButton,
    handleEmailButton,
  } = useBasicdetails({
    refetch,
    providersState,
    formik,
    getFieldError,
    setStatus,
    status,
  });
  return (
    <div className="col">
      <div className="input-wrap pro-mb-4">
        <Input
          label={"Name"}
          type="text"
          id="name"
          name="name"
          className={`pro-input lg ${getFieldError("name") && " error"}`}
          {...formik.getFieldProps("name")}
          error={getFieldError("name")}
          errorMessage={getFieldError("name")}
        />
      </div>
      <div className="pro-mb-4">
        <FileUpload
          formik={formik}
          title={"Image"}
          filePreview={imagePreview}
          handleFileChange={handleProfileChange}
          handleFileRemove={handleProfileRemove}
          getFieldError={getFieldError}
          isError={getFieldError("profile_image")}
        />
        {getFieldError("profile_image") && (
          <span className="error-custom">{getFieldError("profile_image")}</span>
        )}
      </div>
      <div className="input-country-wrap ">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Phone
        </label>
        <div
          className={`pro-mb-4 pro-d-flex  input-btn-group  pro-w-100 ${
            formik.errors.phone && formik.touched.phone && "error-input-wrap"
          }`}
        >
          <div className="input-main-wrap ">
            <div className="code-wrap ">
              <Select
                id="country_code"
                options={countryCodeOptions}
                getOptionLabel={(option) => `${option.label}`}
                getOptionValue={(option) => `${option.value}`}
                value={formik?.values?.country_code}
                classNamePrefix="pro-input"
                className="country-select"
                onBlur={formik.handleBlur("country_code")}
                menuPlacement="auto"
                onChange={(value) => {
                  formik.setFieldValue("country_code", value);
                  numberChange(value);
                }}
                isDisabled={
                  providersState?.phoneverified ||
                  submitname === "Edit" ||
                  submitname === "Verified"
                }
              ></Select>
            </div>
            <input
              type="text"
              id="phone"
              name="phone"
              disabled={
                providersState?.phoneverified ||
                submitname === "Edit" ||
                submitname === "Verified"
              }
              className={`pro-input lg ${
                formik.errors.phone && formik.touched.phone && "error"
              }`}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              value={formik.values.phone}
              onBlur={formik.handleBlur}
              onKeyDown={(e) => {
                const regex = /^[0-9]$/;
                const allowedKeys = [
                  "Backspace",
                  "ArrowLeft",
                  "ArrowRight",
                  "Tab",
                  "Delete",
                ];
                if (!regex.test(e.key) && !allowedKeys.includes(e.key)) {
                  e.preventDefault();
                }
              }}
            />
            {formik.errors.phone && formik.touched.phone && (
              <span className="error-text">{formik.errors.phone}</span>
            )}
          </div>
          <Button
            disabled={
              submitname === "Verified" || providersState?.phoneverified
            }
            onClick={() => handlePhoneButton()}
            type="button"
            className={'pro-btn-outline'}
          >
            {providersState?.phoneverified ? "Verified" : submitname}
          </Button>
        </div>
      </div>
      {otpnumbershown && (
        <div className="pro-mb-4 pro-d-flex   input-btn-group pro-w-100 ">
          <Input
            label={"Enter OTP"}
            type="text"
            id="otp"
            name="otp"
            className={`pro-input lg}`}
            onChange={(e) => setOtpnumber(e.target.value)}
            value={otpnumber ?? ""}
            maxLength={6}
            onKeyDown={(e) => {
              const regex = /^[0-9]$/;
              const allowedKeys = [
                "Backspace",
                "ArrowLeft",
                "ArrowRight",
                "Tab",
                "Delete",
              ];
              if (!regex.test(e.key) && !allowedKeys.includes(e.key)) {
                e.preventDefault();
              }
            }}
          />
          <div className="verify-btn">
            <Button onClick={() => otpverify()} className={'pro-btn-outline'} type="button">
              Verify
            </Button>
            <button className="resend pro-btn-link" onClick={() => otpreq()} type="button">
              Resend OTP
            </button>
          </div>
        </div>
      )}
      <div
        className={`pro-mb-4 pro-d-flex  input-btn-group pro-w-100  ${
          getFieldError("email") && " error-input-wrap"
        }`}
      >
        <Input
          label={"Email"}
          type="text"
          id="email"
          name="email"
          className={`pro-input lg ${getFieldError("email") && " error"}`}
          onChange={(e) => {
            formik.handleChange(e);
          }}
          disabled={
            providersState?.emailverified ||
            submitemail === "Edit" ||
            submitemail === "Verified"
          }
          value={formik.values.email}
          onBlur={formik.handleBlur}
          error={getFieldError("email")}
          errorMessage={getFieldError("email")}
        />
        <Button
          disabled={submitemail === "Verified" || providersState?.emailverified}
          onClick={() => handleEmailButton()}
          className={'pro-btn-outline'}
          type="button"
        >
          {providersState?.emailverified ? "Verified" : submitemail}
        </Button>
      </div>
      {otpemailshown && (
        <div className="pro-mb-4 pro-d-flex  input-btn-group pro-w-100 ">
          <Input
            label={"Enter OTP"}
            type="text"
            id="otp"
            name="otp"
            className={`pro-input lg}`}
            onChange={(e) => setOtpemail(e.target.value)}
            value={otpemail ?? ""}
            maxLength={6}
            onKeyDown={(e) => {
              const regex = /^[0-9]$/;
              const allowedKeys = [
                "Backspace",
                "ArrowLeft",
                "ArrowRight",
                "Tab",
                "Delete",
              ];
              if (!regex.test(e.key) && !allowedKeys.includes(e.key)) {
                e.preventDefault();
              }
            }}
          />
          <div className="verify-btn">
            <Button onClick={() => otpverifyemail()} type="button" className={'pro-btn-outline'}> 
              Verify
            </Button>
            <button className="resend pro-btn-link" onClick={() => otpreqemail()} type="button">
              Resend OTP
            </button>
          </div>
        </div>
      )}
      <div className={`col-12 pro-mb-4`}>
        <label htmlFor="dob" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Date of birth
        </label>
        <Input
          max={new Date().toISOString().slice(0, 10)}
          className={`pro-input lg  ${getFieldError("dob") && " error"}`}
          type="date"
          name="dob"
          placeholder="Select Date of birth"
          // onChange={handleStartDateChange}
          onBlur={formik?.handleBlur("dob")}
          value={formik.values.dob}
          {...formik.getFieldProps("dob")}
          error={getFieldError("dob")}
          errorMessage={getFieldError("dob")}
        />
      </div>
      <div className="pro-mb-4">
        <label
          htmlFor="servicetype"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Gender
        </label>
        <Select
          id="gender"
          placeholder={"Gender"}
          className={`pro-input lg  ${getFieldError("gender") && " error"}`}
          classNamePrefix="pro-input"
          name="gender"
          options={basicData?.gender ?? []}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={formik?.values?.gender}
          onBlur={formik.handleBlur("gender")}
          onChange={(value) => formik.setFieldValue("gender", value ?? "")}
          menuPlacement="auto"
          isClearable
        />
        {getFieldError("gender") && (
          <span className="error-text">{getFieldError("gender")}</span>
        )}
      </div>
      <div className="pro-mb-4">
        <Input
          label={"Bio"}
          type="text"
          id="bio"
          name="bio"
          className={`pro-input lg ${getFieldError("bio") && " error"}`}
          {...formik.getFieldProps("bio")}
          error={getFieldError("bio")}
          errorMessage={getFieldError("bio")}
        />
      </div>
      <div className="pro-mb-4">
        <GoogleMapsbasic formik={formik} />
      </div>
      {formik.touched.maps && formik.errors.maps && (
        <span className="pro-text-error pro-my-2">{formik.errors.maps}</span>
      )}
   <div className="pro-mb-4">
      <Input
        label={"Location"}
        type="text"
        id="address"
        readOnly
        name="address"
        className={`pro-input lg ${getFieldError("address") && " error"}`}
        {...formik.getFieldProps("address")}
        error={getFieldError("address")}
        errorMessage={getFieldError("address")}
      />
      </div>
      <div className="pro-mb-4">
        <Input
          label={"Experience"}
          type="text"
          id="experience"
          name="experience"
          className={`pro-input lg ${getFieldError("experience") && " error"}`}
          {...formik.getFieldProps("experience")}
          error={getFieldError("experience")}
          errorMessage={getFieldError("experience")}
          maxLength={2}
          onKeyDown={(e) => {
            const regex = /^[0-9]$/;
            const allowedKeys = [
              "Backspace",
              "ArrowLeft",
              "ArrowRight",
              "Tab",
              "Delete",
            ];
            if (!regex.test(e.key) && !allowedKeys.includes(e.key)) {
              e.preventDefault();
            }
          }}
        />
      </div>

      {isEdit && (
        <div className="pro-toggle">
          <div className="pro-toggle-box ">
            <input
              type="checkbox"
              role="switch"
              id="status"
              checked={status}
              onChange={handleStatusChange}
            />
            <span></span>
          </div>
          <label htmlFor="status">Status</label>
        </div>
      )}

      <div
        className={`col-12 pro-d-flex pro-justify-end ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>

        <Button
          type="button"
          className={"pro-btn-primary lg pro-ms-3"}
          onClick={handleNext}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default Basicdetails;
