import Style from "./NotificationListing.module.scss";
import { Image } from "@wac-ui-dashboard/wac_component_library";
import useNotificationListing from "./useNotificationListing";
import Assets from "../../../Assets";
import NotificationListingShimmer from "../../Global/Shimmers/NotificationListingShimmer";

const NotificationListing = () => {
  const {
    state,
    showScroll,
    notificationState,
    lastElement,
    handleRedirect,
    handleScrollToTop,
  } = useNotificationListing();
  return (
    <>
      <div className={`${Style.wrapper} pro-py-5`}>
        {state?.notificationStatus === "pending" && !notificationState?.data ? (
          <>
            <div className={`${Style.root} pro-pt-2`}>
              <div className={`${Style.list_wrap} pro-p-5`}>
                {[...Array(10)].map((_, i) => (
                  <NotificationListingShimmer key={i} />
                ))}
              </div>
            </div>
          </>
        ) : (
          <>
            {showScroll && (
              <button
                className={`scoll-top`}
                onClick={() => handleScrollToTop()}
              >
                <span className="material-symbols-outlined">expand_less</span>
              </button>
            )}

            <div className={`${Style.root} pro-pt-2`}>
              <div className={`${Style.list_wrap} pro-p-5`}>
                {notificationState?.data?.map((item, index) => {
                  return (
                    <div
                      className={`${Style.notification_item}  ${item?.is_read !== 1 ? Style.un_read : ''
                        }`}
                      onClick={() => handleRedirect(item)}
                      key={index}
                      ref={
                        index === notificationState?.data?.length - 5
                          ? lastElement
                          : false || null
                      }
                    >
                      <div className="pro-d-flex pro-gap-3 ">
                        <div className={`${Style.img_wrap} `}>
                          <Image
                            width={53}
                            height={53}
                            alt={`img`}
                            src={`${item?.image ?? Assets.ADMIN_PROFILE
                              }`}
                          />
                        </div>
                        <div className={Style.notify_msg}>
                          <p>{item?.message}</p>
                          <div className="pro-d-flex pro-items-center pro-mt-2 ">
                            <span className="pro-badge pro-me-2">{item?.label}</span>
                            <span className={`${Style.date} pro-font-xs`}>
                              {item?.received_on}
                            </span>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  );
                })}

                {state?.notificationStatus === "pending" &&
                  notificationState?.data ? (
                  <>
                    {[...Array(10)].map((_, i) => (
                      <NotificationListingShimmer key={i} />
                    ))}
                  </>
                ) : (
                  <></>
                )}
                {notificationState?.data?.length < 1 && (
                  <div
                    className={`${Style.no_item_msg} pro-d-flex pro-flex-column pro-text-center pro-py-6`}
                  >
                    <span
                      className={`${Style.icon} pro-mx-auto pro-rounded-circle pro-d-flex pro-justify-center`}
                    >
                      <span className="material-symbols-outlined x4">
                        {" "}
                        notifications{" "}
                      </span>
                    </span>
                    <h6 className="pro-ttl pro-mb-0 pro-mt-5">
                      No notification right now
                    </h6>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default NotificationListing;
