import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  OffCanvasLayout,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import EmptyData from "../../Global/EmptyData";
import AddStaff from "./AddStaff";
import useStaff from "./useStaff";
import FilterStaff from "./FilterStaff";
import OrderColumn from "../../Global/OrderColumn";


const Staff = () => {
  const {
    isLoading,
    isFetching,
    tableFields,
    staffList,
    staffState,
    paginationOptions,
    getRow,
    refetch,
    handleSort,
    handleSearch,
    handlePageSize,
    handleEditAction,
    handlePagination,
    handleCloseClick,
    handleClearClick,
    handleCreateClick,
    handleDeleteModal,
    handleDeleteCloseModal,
    handleDeleteAction,
    handleFilter,
    handleCloseFilter,
    actionOptions,
    handleActionChange,
    updateStatusFields,
    showEditModal,
    handleDateChange,
    initialDate,
  } = useStaff();
  return (
    <div>
      <HeadingGroup
        title={"Staff"}
        extraClassName={`pro-mb-1 heading-wrap`}
        buttonTitle={staffList?.data?.permission?.can_create && "Create"}
        handleClick={handleCreateClick}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <div className="pro-d-flex search-filter-datepicker-wrap search_filter_shimmer">
          <SearchFilters
            onSearchInput={handleSearch}
            showActions={false}
            loading={isLoading}
            SearchIcon={
              <span className="material-symbols-outlined"> search </span>
            }
            // showClearFilters
            // handleClear={handleClearClick}
            searchInputProps={{ value: staffState?.search }}
            actionOptions={actionOptions?.filter((item) => item.value === 0)}
            onActionOptionChange={handleActionChange}
            isDateRange
            showDateRange={true}
            initialDateRange={initialDate}
            onDateChange={handleDateChange}
            extraFilters={
              <>
              <div className="col-auto">      
                <button
                  className={"pro-btn pro-items-center pro-btn-outline pro-mx-1"}
                  onClick={handleFilter}
                >
                  <span className="material-symbols-outlined x4">tune</span>
                  Filter
                </button>
              </div>
              <div className="col-auto pro-d-flex">
                <button
                  className={" pro-btn-link lg"}
                  onClick={handleClearClick}
                >             
                  Clear
                </button>
              </div>
              </>
            }
          />

        </div>
        {staffList?.data?.data?.length !== 0 ? (
          <>
            <Table
              multiSelect={false}
              data={staffList?.data?.data || []}
              uniqueID={"_id"}
              fields={tableFields}
              SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              handleDelete={handleDeleteModal}
              handleEdit={handleEditAction}
              handleSort={handleSort}
              clear={staffState.clearSelection}
              getRow={getRow}
              loading={isFetching}
              perpage={staffState?.currentPageSize}
              editable={staffList?.data?.permission?.can_update}
              deletable={staffList?.data?.permission?.can_delete}
              assignable={false}
              showCheckBox={staffList?.data?.permission?.can_update}
            />
            {staffList?.data?.data?.length > 0 && (
              
              <Pagination
                currentPage={staffState?.currentPage}
                totalPageCount={Math.ceil(
                  staffList?.data?.total /
                  staffState.currentPageSize
                )}
                options={paginationOptions}
                onPageChange={handlePagination}
                onActionChange={handlePageSize}
                center
              />
            )}
          </>
        ) : (
          <EmptyData />
        )}
       <OffCanvasLayout
          show={staffState?.createModal}
          handleClose={handleCloseClick}
          title={staffState?.isEdit ? "Edit Staff" : "Staff Register"}
          closeicon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
        >
          <AddStaff refetch={refetch} />
        </OffCanvasLayout>
        <OffCanvasLayout
          show={staffState?.createFilterModal}
          handleClose={handleCloseFilter}
          title={"Filter"}
          closeicon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
        >
          <FilterStaff refetch={refetch} />
        </OffCanvasLayout>
        <ModalLayout
          show={staffState?.deleteModal}
          handleClose={handleDeleteCloseModal}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={"Are you sure you want to delete?"}
              subTitle={"This action cannot be undo "}
              cancelText={"No"}
              cancelAction={handleDeleteCloseModal}
              submitText={"Yes"}
              submitAction={handleDeleteAction}
              isRight={true}
            />
          </div>
        </ModalLayout>
        <ModalLayout show={showEditModal} handleClose={handleCloseClick}>
          <div className="pro-m-5">
          <OrderColumn
            title={"Choose which columns you see"}
            tableFields={staffList?.data?.fields}
            moduleId={staffList?.data?.module_id}
            updateData={updateStatusFields} 
            refetch={refetch}
          />
          </div>
        </ModalLayout>
      </div>
    </div>
  );
};

export default Staff;
