import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "",
  start: "",
  end: "",
  search: "",
  status: "",
  createModal: false,
  isEdit: false,
  itemId: "",
  itemData: "",
  imagePreview: "",
  deleteModal: false,
  clearSelection: false,
  createFilterModal: false,
  phoneverified: false,
  emailverified: false,
  formActiveTab: "BasicDetails",
  documents: [],
  service_type_id:[],
  languages:[],
  maps: {
    lat: 23.4241,
    lng: 53.8478,
    place: "",
  },
  selectedLocation: {
    lat: "",
    lng: "",
    place: "",
  },
};

const ProvidersSlice = createSlice({
  name: "providersslice",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = ProvidersSlice.actions;
export default ProvidersSlice.reducer;
