import { useSelector } from "react-redux";
import { useRoleTableHeadDataMutation,useGetRoleDataQuery } from "../../../Store/Queries/Role";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../Store/Slices/Global";
import { updateConfig } from "../../../Store/Slices/Role/roleSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteRole } from "./api";

const useRole = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const roleState = useSelector((state) => state.role);
  const { tableFields,showEditModal} = useSelector((state) => state.global);
  let actionOptions = [{ label: "Edit Columns", value: 0 }];
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const {
    data: roleList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetRoleDataQuery({
    items_per_page: roleState.currentPageSize,
    current_page: roleState.currentPage,
    sort_by: roleState.sortBy,
    sort_order: roleState.sortOrder,
    search: roleState.search,
    status:roleState?.status,
  });
  const [updateServiceTypeFields] = useRoleTableHeadDataMutation();
  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = roleList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [roleList]);


  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      booking: (feild, data) =>  <img src={data[feild]} alt="staff_image" />,
      cms: (feild, data) =>  <img src={data[feild]} alt="staff_image" />,
      configure: (feild, data) =>  <img src={data[feild]} alt="staff_image" />,
      dashboard: (feild, data) =>  <img src={data[feild]} alt="staff_image" />,
      payments: (feild, data) =>  <img src={data[feild]} alt="staff_image" />,
      posts: (feild, data) =>  <img src={data[feild]} alt="staff_image" />,
      users: (feild, data) =>  <img src={data[feild]} alt="staff_image" />,
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => {
      state.currentPageSize= 10;
      state.currentPage= 1;
      state.sortBy = "";
      state.sortOrder = "";
      state.search = "";
      state.status = "";
    }));
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (roleState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = roleState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditAction = (e) => {
    navigate({
      pathname: "/configure/role/role-permissions",
      search: `role=${e?.[0]}&update=true`,
    });
    dispatch(
      updateConfig((state) => {
        state.isEdit = true;
      })
    );
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.isEdit = false;
        state.addModal = true;
      })
    );
  };

  const handleCloseClick = () => {
    dispatch(updateConfig((state) => (state.addModal = false)));
  };
  const handleDeleteAction = () => {
    let id = roleState?.itemId;
      deleteRole(id).then((response) => {
        if (response?.status === 200) {
          toast.success("Deleted");
          refetch();
          handleDeleteCloseModal();
          dispatch(updateConfig((state) => (state.clearSelection = true)));
          const clearSelection = setTimeout(() => {
            dispatch(updateConfig((state) => (state.clearSelection = false)));
            clearTimeout(clearSelection);
          }, 500);
        } else {
          toast.error("Something went wrong");
          handleDeleteCloseModal()
        }
      });
  };
  
  const handleDeleteCloseModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.deleteModal = !state.deleteModal;
        state.itemId = ''
        state.itemData=''
      })
    );
  };
  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.deleteModal = !state.deleteModal;
        state.itemId = e?.[0];
      })
    );
  };
  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };
  return {
    isLoading,
    isFetching,
    tableFields,
    roleList,
    roleState,
    paginationOptions,
    getRow,
    refetch,
    handleSort,
    handleSearch,
    handlePageSize,
    handleEditAction,
    handlePagination,
    handleCloseClick,
    handleClearClick,
    handleCreateClick,
    handleDeleteModal,
    handleDeleteCloseModal,
    handleDeleteAction,
    actionOptions,
    handleActionChange,
    updateServiceTypeFields,
    showEditModal
  };
  
};

export default useRole;
