import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getProfileData, updateConfig } from "../../../../Store/Slices/Global";
import { useGetNotificationsListDataQuery } from "../../../../Store/Queries/Notifications";
import { useEffect } from "react";
import { getNotificationData,updateConfig as notificationUpdateConfig } from "../../../../Store/Slices/Notifications/notificationSlice";
import { MarkNotificationRead } from "./api";

const useHeaderActions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const globalState = useSelector((state) => state.global);
  const notificationsState = useSelector((state) => state.notifications);

  const { data: notifications = {}, isFetching: notificationsFetching,refetch} =
    useGetNotificationsListDataQuery({
      items_per_page: notificationsState.currentPageSize,
      current_page: notificationsState.currentPage,
    });
  useEffect(() => {
    // let notificationData = notifications?.data?.data?.filter(
    //   (item) => item.is_read=== 0
    // );
      dispatch(
        notificationUpdateConfig((state) => {
          state.isNewNotification = notifications?.data?.notification_exists;
        })
      ); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications?.data]);
  const handleLogout = () => {
    localStorage.removeItem("USER_ACCESS_TOKEN_NIMBLE");
    localStorage.removeItem("menu_permissions");
    localStorage.removeItem("basicData");
    navigate("/");
  };

  const handleSettings = () => {
    navigate("/Settings");
  };

  const handleViewProfile = () => {
    dispatch(updateConfig((state) => (state.profileModal = true)));
    dispatch(getProfileData());
  };

  const handleCloseProfile = () => {
    dispatch(updateConfig((state) => (state.profileModal = false)));
  };
  const handleNotificationsRedirect = () => {
    navigate("/notifications");
  };

  const handleNotificationRedirect = async (item) => {
    const url = item?.redirection_url;
    const id = item?._id;
    let data = {
      notification_id: id,
    };
    MarkNotificationRead(data).then(() => {});
    refetch()
    dispatch(
      getNotificationData({
        page: 1,
        notification_id: "notificationData",
      })
    );
    navigate(`/${url}`);
  };
  return {
    globalState,
    notifications,
    isNewNotification: notificationsState.isNewNotification,
    notificationsFetching,
    handleLogout,
    handleSettings,
    handleViewProfile,
    handleNotificationRedirect,
    handleCloseProfile,
    handleNotificationsRedirect,
  };
};

export default useHeaderActions;
