import React from 'react'
import { Outlet } from 'react-router-dom'
import CommonLayout from '../../Pages/Layouts/CommonLayout'

const Posts = () => {
  return (
    <CommonLayout dashboard>
      <Outlet></Outlet>
    </CommonLayout>
  )
}

export default Posts
