import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../Store/Slices/ServiceTypes/servicetypesSlice";
import * as Yup from "yup";

const useFilterServiceType = ({ refetch }) => {
  const dispatch = useDispatch();
  // const [status, setStatus] = useState(true);
  const { basicData } = useSelector((state) => state.global);
  const { isEdit} = useSelector((state) => state.servicetypes);

  const validationSchema = Yup.object({
    status: Yup.object()
  });

  const formik = useFormik({
    initialValues: {
      status: '',
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(updateConfig((state) => (state.status = values?.status?.id?.toString()??"")));
      handleCloseModal()
    },
  });

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleCloseModal = () => {
    dispatch(updateConfig((state) => (state.createFilterModal = false)));
  };

  return {
    formik, isEdit, getFieldError, handleCloseModal ,basicData
  };
};

export default useFilterServiceType;
