import { useNavigate, useParams } from "react-router-dom";
import { useGetProviderdetailQuery } from "../../../../Store/Queries/Users/Providers";
import {  useState } from "react";

const useProvidersDetailsLayout = () => {
  const { providerID } = useParams();
  const activeProvider =
  providerID.replace(/activeTab=[^&]+/, "") 
  const [showAllDesc, setShowAllDesc] = useState(false);
  const navigate = useNavigate();
  const { data: providerData, isFetching } = useGetProviderdetailQuery({
    service_provider_id: activeProvider,
  });
  const handleBackClick = () => {
    navigate("/users/providers");
  };

  const handleReadMoreDesc = () => {
    setShowAllDesc(true);
  };

  return {
    providerID,
    isFetching,
    providerData,
    showAllDesc,
    handleReadMoreDesc,
    handleBackClick 
  };
};

export default useProvidersDetailsLayout;
