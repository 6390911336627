import React from "react";
import Style from "./uploadPhoto.module.scss";
import { Image } from "@wac-ui-dashboard/wac_component_library";
const UploadPhoto = ({
  title,
  handleBlur,
  documents = [],
  handleChange,
  handleDeleteClick,
  mutiple = false,
  addText,
  errorMsg,
  isError,
  accept = "",
  showAddButton = true,
  status = "",
}) => {
  return (
    <div className={Style.wrapper}>
      <label className="pro-mb-1 pro-font-sm pro-fw-medium">
        {title ?? ""}
      </label>
      <div
        className={`pro-d-flex pro-flex-wrap pro-gap-3 pro-p-2 ${Style.upload_wrap}`}
      >
        {documents && documents?.[0] && (
          <>
            {documents?.map((value, index) => {
              return (
                <div className={Style.root} key={index}>
                  <Image
                    src={value}
                    width={74.71}
                    height={79.84}
                    alt={"banner-preview"}
                    propStyle={{ root: Style.img_root }}
                  />
                  <span
                    className={`${Style.delete_btn}`}
                    onClick={() => handleDeleteClick(value, index)}
                  >
                    <span
                      className={`material-symbols-outlined ${Style.delete_icon}`}
                    >
                      delete
                    </span>
                  </span>
                </div>
              );
            })}
          </>
        )}
        {showAddButton && (
          <div
            className={`${Style.upload_box} pro-d-flex pro-items-center pro-justify-center pro-p-3 ${isError && Style.error}`}
          >
            {showAddButton && (
              <>
                {status === "pending" ? (
                  <div className="app-loading"></div>
                ) : (
                  <div className="pro-d-flex pro-justify-center pro-items-center pro-flex-column pro-text-center">
                    <span className="material-symbols-outlined x3">backup</span>
                    <span className={`${Style.add_text} pro-fw-medium `}>
                      {addText ?? "Add Images"}
                    </span>
                  </div>
                )}
              </>
            )}
            <input
              type="file"
              className={`${Style.file}`}
              onChange={handleChange}
              multiple={mutiple}
              accept={accept}
              onBlur={handleBlur}
            />
          </div>
        )}
        {errorMsg && isError && <span className="error-custom">{errorMsg}</span>}
      </div>
    </div>
  );
};

export default UploadPhoto;
