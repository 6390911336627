import { getAxiosInstance } from "../../API";

export const updateStatus = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.put(`/admin/posts/status-update`, body);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};
export const deleteChats = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.delete(`/admin/posts/delete?post_id=${id}`);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};

export const acceptORrejectChat= async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/chat-history/status-change`, body);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};
export const deactivateChat = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/chat-history/deactive-user`, body);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};
