import { useSelector } from "react-redux";
import {
  useGetChatDataQuery,
  useChatTableHeadDataMutation,
} from "../../../Store/Queries/Chat";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../Store/Slices/Global";
import {
  updateConfig,
  getMessageList,
} from "../../../Store/Slices/Booking/chatSlice";
import {
  deleteChats,
  acceptORrejectChat,
  deactivateChat,
} from "../api";
import { toast } from "react-toastify";
import { subDays, format } from "date-fns";
import { IconText } from "@wac-ui-dashboard/wac_component_library";
import useObserver from "../../../utils/hooks/useObserver";

const useChat = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const chatsState = useSelector((state) => state.chat);
  const { tableFields, showEditModal, chatModal } = useSelector(
    (state) => state.global
  );
  let actionOptions = [{ label: "Edit Columns", value: 0 }];
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const [initialDate, setInitialDate] = useState({
    startDate: subDays(new Date(), 30),
    endDate: subDays(new Date(), 0),
    key: "selection",
  });
  const {
    data: chatsList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetChatDataQuery({
    page_size: chatsState.currentPageSize,
    currentPage: chatsState.currentPage,
    sort_by: chatsState.sortBy,
    sort_order: chatsState.sortOrder,
    start:
      chatsState.start === ""
        ? format(initialDate?.startDate, "dd-MM-yyyy")
        : chatsState.start,
    end:
      chatsState.end === ""
        ? format(initialDate?.endDate, "dd-MM-yyyy")
        : chatsState.end,
    search: chatsState.search,
    // status:chatsState?.status,
    // service_type_id:chatsState?.service_type_id,
    // schedule:chatsState?.schedule,
  });
  const handleLoadMore = () => {
    dispatch(
      updateConfig((state) => {
        state.messageFullData.total_page += 1;
      })
    );
    setPage((prev) => prev + 1);
    dispatch(
      getMessageList({
        chat_history_id: chatsState?.itemId,
        page: page + 1,
        per_page: 10,
      })
    );
  };
  const { lastElement } = useObserver({
    loading: chatsState?.message_status === "pending",
    hasMore:
      chatsState?.messageFullData?.items_per_page *
        chatsState?.messageFullData?.current_page <
      chatsState?.messageFullData?.total_count,
    callback: handleLoadMore,
  });

  const [updateStatusFields] = useChatTableHeadDataMutation();
  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = chatsList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [chatsList]);

  const handleOpenModal = (data) => {
    dispatch(
      updateConfig((state) => {
        state.chatStatus = data?.status;
        state.itemName=data?.service_request;
        state.itemId = data?.id;
        state.chatModal = true;
      })
    );
    dispatch(
      getMessageList({ chat_history_id: data?.id, page: 1, per_page: 10 })
    );
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.chatModal = false;
      })
    );
  };

  const handleOpenDeactivateModal = ()=>{
    dispatch(
      updateConfig((state) => {
        state.deactivateModal = true;
      })
    );
  }

  const handleCloseDeactivateModal = ()=>{
    dispatch(
      updateConfig((state) => {
        state.deactivateModal = false;
      })
    );
  }
  const handleDeactivate = () => {
    const body = {
      user_id: chatsState?.messageFullData?.user_id??"",
    };
    deactivateChat(body).then((response) => {
      if (response?.status === 200) {
        toast.success("Accepted");
        dispatch(
          updateConfig((state) => {
            state.deactivateModal = false;
          })
        );
        refetch();
      } else {
        dispatch(
          updateConfig((state) => {
            state.deactivateModal = false;
          })
        );
        toast.error("Something went wrong");
      }
    });
  };


  const handleAccept = (id) => {
    const body = {
      chat_history_id: id,
      status: 1,
    };
    acceptORrejectChat(body).then((response) => {
      if (response?.status === 200) {
        toast.success("Accepted");
        refetch();
      } else {
        toast.error("Something went wrong");
      }
    });
  };
  const handleReject = (id) => {
    const body = {
      chat_history_id: id,
      status: 2,
    };
    acceptORrejectChat(body).then((response) => {
      if (response?.status === 200) {
        toast.success("Rejected");
        refetch();
      } else {
        toast.error("Something went wrong");
      }
    });
  };
  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      user: (feild, data) => (
        <div
          className="text-truncate icontxt_no_hover"
          onClick={handleOpenModal}
        >
          <IconText
            icon={
              <img
                src={data["profile_image"]}
                alt={"Profile-pic"}
                width={20}
                height={20}
                className="object-fit-cover"
              />
            }
            title={data[feild]}
            propStyle={{
              rootIcon:
                "pro-d-flex pro-items-center pro-rounded-circle pro-overflow-hidden ",
            }}
          />
        </div>
      ),
      status: (feild, data) => {
        return (
          <>
            <div className="row">
              <div className="pro-toggle-box">
                {data?.can_accept ? (
                  <div className="pro-d-flex">
                  
                    <button
                      className={
                        "pro-btn pro-items-center pro-btn-outline pro-mx-1"
                      }
                      onClick={() => handleReject(data?.id)}
                    >
                      Reject
                    </button>
                    <button
                      className={
                        "pro-bg-primary pro-btn pro-btn-outline pro-items-center pro-mx-1"
                      }
                      onClick={() => handleAccept(data?.id)}
                    >
                      Accept
                    </button>
                  </div>
                ) : (
                  <p
                    className={`pro-badge pro-mb-0 ${
                      data[feild] === "Completed"
                        ? "badge-success-outline"
                        : data[feild] === "Accepted"
                        ? " badge-success-outline"
                        : data[feild] === "Pending"
                        ? " badge-warning-outline"
                        : data[feild] === "Rejected"
                        ? " badge-danger-outline "
                        : "badge-outline"
                    }`}
                  >
                    {data[feild]}
                  </p>
                )}
              </div>
            </div>
          </>
        );
      },
      service_request: (field, data) => (
        <p className="pro-pnt pro-mb-0" onClick={() => handleOpenModal(data)}>
          {data[feild]}
        </p>
      ),
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };
  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = 10;
        state.currentPage = 1;
        state.sortBy = "";
        state.sortOrder = "";
        state.start = format(subDays(new Date(), 30), "dd-MM-yyyy");
        state.end = format(new Date(), "dd-MM-yyyy");
        state.search = "";
        state.status = "";
        state.service_type_id = "";
        state.schedule = "";
      })
    );
    setInitialDate({
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: "selection",
    });
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (chatsState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = chatsState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.isEdit = false;
        state.createModal = true;
      })
    );
  };

  const handleCloseClick = () => {
    dispatch(updateConfig((state) => (state.createModal = false)));
  };
  const handleDeleteAction = () => {
    let id = chatsState?.itemId;
    deleteChats(id).then((response) => {
      if (response?.status === 200) {
        toast.success("Deleted");
        refetch();
        handleDeleteCloseModal();
        dispatch(updateConfig((state) => (state.clearSelection = true)));
        const clearSelection = setTimeout(() => {
          dispatch(updateConfig((state) => (state.clearSelection = false)));
          clearTimeout(clearSelection);
        }, 500);
      } else {
        toast.error("Something went wrong");
        handleDeleteCloseModal();
      }
    });
  };
  const handleDateChange = (date) => {
    dispatch(
      updateConfig((state) => {
        state.start = format(new Date(date.startDate), "dd-MM-yyyy");
        state.end = format(new Date(date.endDate), "dd-MM-yyyy");
      })
    );
  };
  const handleDeleteCloseModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.deleteModal = !state.deleteModal;
        state.itemId = "";
        state.itemData = "";
      })
    );
  };
  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.deleteModal = !state.deleteModal;
        state.itemId = e?.[0];
      })
    );
  };
  // const handleFilter = () => {
  //   dispatch(
  //     updateConfig((state) => {
  //       state.isFilter = false;
  //       state.createFilterModal = true;
  //     })
  //   );
  // };
  const handleCloseFilter = () => {
    dispatch(updateConfig((state) => (state.createFilterModal = false)));
  };
  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };

  return {
    isLoading,
    isFetching,
    tableFields,
    chatsList,
    chatsState,
    paginationOptions,
    getRow,
    refetch,
    handleSort,
    handleSearch,
    handlePageSize,
    handlePagination,
    handleCloseClick,
    handleClearClick,
    handleCreateClick,
    handleDeleteModal,
    handleDeleteCloseModal,
    handleDeleteAction,
    // handleFilter,
    handleCloseFilter,
    actionOptions,
    handleActionChange,
    updateStatusFields,
    showEditModal,
    chatModal,
    handleDateChange,
    initialDate,
    handleCloseModal,
    lastElement,
    handleDeactivate,
    handleOpenDeactivateModal,
    handleCloseDeactivateModal,
  };
};

export default useChat;
