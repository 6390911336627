import axios from "axios";
const apiUrls = {
  WEBC: process.env.REACT_APP_APIURL,
  UAT: process.env.REACT_APP_UAT_APIURL,
  LIVE: process.env.REACT_APP_LIVE_APIURL,
  QA:process.env.REACT_APP_QA_APIURL,
};
const BASE_URL = apiUrls[process.env.REACT_APP_TYPE] || "invalid base uri";
let token = "";

export const getAxiosInstance = async () => {
  try {
    token = localStorage.getItem("USER_ACCESS_TOKEN_NIMBLE");
  } catch (e) {
  } finally {
    const instance = axios.create({
      baseURL: BASE_URL,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    instance.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error.response) {
          if (error?.response?.status === 401) {
            if (!window?.location?.href.includes("login")) {
              localStorage.clear("USER_ACCESS_TOKEN_NIMBLE");
              localStorage.removeItem("menu_permissions");
              localStorage.removeItem("basicData");
              window.location.href = "/login";
            }
          }
        }
        return Promise.reject(error);
      }
    );

    return instance;
  }
};
