import React from "react";
import "../Assets/scss/app.scss";
 import "@wac-ui-dashboard/wac_component_library/scss/variables.scss";
import "@wac-ui-dashboard/wac_component_library/scss/common.scss";
import Routes from "../Routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div>
      <Routes/>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        className={`pro-toast-container`}
        progressClassName={`pro-toast-progress`}
        toastClassName={`pro-toast`}
        style={{ zIndex: 9999 }}
      />
    </div>
  );
}

export default App;
