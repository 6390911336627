import { useSelector } from "react-redux";
import { useGetSeekerpostsQuery } from "../../../../../Store/Queries/Users/Seekers";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateConfig as globalUpdateConfig} from "../../../../../Store/Slices/Global";
import { updateConfig } from "../../../../../Store/Slices/Users/seekerdetailSlice";

const useSeekerPosts = ({activeSeeker}) => {
  const dispatch = useDispatch();
  const seekerState = useSelector((state) => state.seekerdetails);
  const { tableFields} = useSelector((state) => state.global);
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const {
    data: seekerPosts = {},
    isLoading,
    isFetching,
  } = useGetSeekerpostsQuery({
    page_size: seekerState?.details_posts?.currentPageSize,
    current_page: seekerState?.details_posts.currentPage,
    sort_by: seekerState?.details_posts.sortBy,
    sort_order: seekerState?.details_posts.sortOrder,
    search: seekerState?.details_posts.search,
    user_id: activeSeeker,
  });
  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = seekerPosts?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [seekerPosts]);
  
  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.details_posts.search = e.target.value;
        state.details_posts.currentPage = 1;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.details_posts.currentPageSize = page_size;
        state.details_posts.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.details_posts.currentPage = page;
      })
    );
  };
  const handleClearClick = () => {
    dispatch(updateConfig((state) => {
      state.details_posts.currentPageSize= 10;
      state.details_posts.currentPage= 1;
      state.details_posts.sortBy = "";
      state.details_posts.sortOrder = "";
      state.details_posts.search = "";
    }));
  };
  return {
    isLoading,
    isFetching,
    tableFields,
    seekerPosts,
    seekerState,
    paginationOptions,
    getRow,
    handleSearch,
    handlePageSize,
    handlePagination,
    handleClearClick
  };
  
};

export default useSeekerPosts;
