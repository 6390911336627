import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../Store/Slices/SubCategory/subcategorySlice";
import * as Yup from "yup";

const useFilterSubCategory= ({ refetch }) => {
  const dispatch = useDispatch();
  // const [status, setStatus] = useState(true);
  const { basicData } = useSelector((state) => state.global);
  const { isEdit} = useSelector((state) => state.subcategory);
  const subCategoryState = useSelector((state) => state.subcategory);

  const validationSchema = Yup.object({
    service_type_id: Yup.string(),
    service_category_id: Yup.string(),
    status: Yup.string()
  });

  const formik = useFormik({
    initialValues: {
      service_type_id:subCategoryState?.service_type_id??"",
      service_category_id:subCategoryState?.service_category_id??"",
      status: subCategoryState?.status? Number(subCategoryState?.status):"",
    },
    validationSchema,
    // enableReinitialize,
    onSubmit: (values) => {
      dispatch(updateConfig((state) => {
        state.service_category_id = values?.service_category_id??""
        state.service_type_id = values?.service_type_id??""
        state.status =values?.status?.toString()??""
      }));
      handleCloseModal()
    },
  });

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleCloseModal = () => {
    dispatch(updateConfig((state) => (state.createFilterModal = false)));
  };

  return {
    formik, isEdit, getFieldError, handleCloseModal ,basicData
  };
};

export default useFilterSubCategory;
