import { getAxiosInstance } from "../../../API";

export const createExpertise= async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/expertise/create`, body);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};

export const updateExpertise = async (body) => {
  const api = await getAxiosInstance();;
  try {
    const response = await api.put(`/admin/expertise/update`, body);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};

export const viewExpertise = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/admin/expertise/edit?expertise_id=${id}`);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};

export const updateStatus = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/expertise/status-change`, body);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};
export const deleteExpertise = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.delete(`/admin/expertise/delete?expertise_id=${id}`);
    return response;
  } catch (error) {
    return error?.response?.data
  }
};